/* FILTRACE */

#product__filter {
	background: #fff;
	padding-left: 0;
	padding-right: 0;
	padding-top: 0;
	position: relative;
	h2 {
		@extend %heading-small;
		padding-top: 0;
		padding-bottom: 10px;
		margin-bottom: 0;
		border-bottom: 1px solid $color-background-2;
		position: relative;
		z-index: 1;
		color: $color-text-1;
		&:before { // bílá šipka v zeleném kolečku
			content: '';
			display: block;
			border: solid $color-link-1;
			border-width: 0;
			display: inline-block;
			padding: 4px;
			right: 50%;
			margin-right: $padding-medium;
			transform: rotate(45deg);
			box-sizing: border-box;
		}
	}
	.switch {
		position: absolute;
		width: 100%;
		text-align: right;
		z-index: 2;
		@media screen and (max-width: 479px) {
			height: (1em * 1.2) * 1.2;
			line-height:  (1em * 1.2) * 1.2;
		}
		@media screen and (min-width: 480px) and (max-width: 1139px) {
			height: (1em * 1.3) * 1.2;
			line-height: (1em * 1.3) * 1.2;
		}
		@media screen and (min-width: 1140px) {
			height: (1em * 1.9) * 1.2;
			line-height: (1em * 1.9) * 1.2;
		}
		a {
			text-decoration: none;
			position: relative;
			display: block;
			color: $color-link-1;
			@media screen and (max-width: 479px) {
				height: (1em * 1.2) * 1.2;
			}
			@media screen and (min-width: 480px) and (max-width: 1139px) {
				height: (1em * 1.3) * 1.2;
			}
			@media screen and (min-width: 1140px) {
				height: (1em * 1.9) * 1.2;
			}

		}
	}
	.parameters,
	.tags,
	.price,
	.fulltext {
		span {
			display: inline-block;
		}
		input {
			padding-top: 0;
			padding-bottom: 0;
		}
	}
	.parameters,
	.tags {
		span {
			margin: 0 1rem 0 0;
		}
		input {
			margin: 0 0.5rem 0 0;
		}
	}
	.parameters {
		margin-bottom: 10px;
		p {
			padding: $padding-small 0;
			border-bottom: 1px solid $color-background-2;
		}
	}
	div.price,
	div.fulltext {
		@media screen and (min-width: 640px) {
			float: left;
		}
		@media screen and (min-width: 1280px) {
			margin-bottom: 0;
		}
		@media screen and (max-width: 1365px) {
			margin-right: $padding-big;
		}
		@media screen and (min-width: 1366px) {
			margin-right: $padding-huge;
		}
	}
	div.price {
		white-space: nowrap;
		input {
			width: 60px;
			text-align: center;
		}
	}
	label {
		margin: 0;
		font-size: 1em;
		&.fulltext {
			max-width: 50%;
		}
	}
	.name, label.fulltext {
		font-weight: bold;
	}
	.submit {
		float: none;
		padding: ($padding-small / 2) $padding-big;
		@media screen and (max-width: 779px) {
			clear: both;
		}
		@media screen and (min-width: 780px) {
			margin: -0.2em 0 0 0;
		}
	}

}
.product__filter--closed {
	margin-bottom: 0;
	h2 {
		&:before { // bílá šipka v zeleném kolečku
			border-width: 4px 4px 0 0 !important;
			@media screen and (max-width: 1139px) {
				margin-bottom: 2px;
			}
			@media screen and (min-width: 1140px) {
				margin-bottom: 6px;
			}
		}
	}
}
.product__filter--open {
	margin-bottom: $font-size;
	border-bottom: 1px solid $color-background-2;
	h2 {
		&:before { // bílá šipka v zeleném kolečku
			border-width: 4px 0 0 4px !important;
			@media screen and (max-width: 1139px) {
				margin-bottom: -1px;
			}
			@media screen and (min-width: 1140px) {
				margin-bottom: 3px;
			}
		}
	}
}

.product__selecting {
	white-space: nowrap;
}


/* RESULT */
.product_result,
.related .product {
	@extend %link-transition;
	text-align: center;
	border-color: #fff;
	padding-top: 30px;
	padding-bottom: 70px;
	box-shadow: 0 0 4px rgba(0,0,0,0.1);
	box-sizing: border-box;
//	border-radius: 25px 0 25px 0;
	.options {
		z-index: 2;
	}
}
.product_result:hover {
	box-shadow: 0 0 8px rgba(0,0,0,0.2);
	a {
		text-decoration: underline;
	}
}
.product_result *,
.related .product * {

}
	.product_result .picture {
		height: 190px;
	}
	.product_result h2,
	.related .product h2 {
		@extend %text-big;
		font-weight: 300;
		overflow: hidden;
		line-height: 1.25;
		font-style: normal;
		@media screen and (max-width: 1024px) {
			height: ($line-height * 1.7em);
		}
		@media screen and (min-width: 1025px) {
			height: ($line-height * 1.7em);
		}

		&:first-line,
		&:first-line {
			font-weight: 600;
		}
	}
	.product_result .perex,
	.related .product .perex {
		overflow: hidden;
		font-size: 100%;
		@media screen and (max-width: 1024px) {
			height: ($line-height * 3em);
		}
		@media screen and (min-width: 1025px) {
			height: ($line-height * 3em);
		}
	}
	.product_result .options {
		bottom: 0;
		margin-bottom: -10px;
		}

/* DETAIL */

.product-h1 {text-align: left;}
.product_view {}
	.product_view .picture {
		margin-bottom: $padding-medium;
	}
	.product_view .perex,
	.product_view .product__top,
	.product_view .product__buyform,
	.product_view .product__nobuyform {
		padding-left: 2%;
		padding-right: 2%;
		box-sizing: border-box;
		@media screen and (max-width: 479px) {
			clear: both;
		}
		@media screen and (min-width: 480px) {
			margin-left: 50%;
			width: 50%;
		}
	}
	.product_view .perex {
		padding-top: 10px;
		padding-bottom: 10px;
		background: $color-background-4;

	}
	.product_view .product__top .value {
		float: none;
		clear: none;
	}
	.product_view .content {
		position: relative;
		clear: both;
		float: left;
		&:before {
			content: ' ';
			position: absolute;
			top: 0;
			left: 0;
			display: block;
			width: 100%;
			height: 1px;
			@media screen and (max-width: 1024px) {
				background: -moz-linear-gradient(left, $color-background-1 50%, $color-background-3 50%); /* FF3.6-15 */
				background: -webkit-linear-gradient(left, $color-background-1 50%, $color-background-3 50%); /* Chrome10-25,Safari5.1-6 */
				background: linear-gradient(to right, $color-background-1 50%, $color-background-3 50%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
				filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$color-background-1', endColorstr='$color-background-2',GradientType=0 ); /* IE6-9 */
			}
			@media screen and (min-width: 1025px) {
				background: -moz-linear-gradient(left, $color-background-1 75%, $color-background-3 25%); /* FF3.6-15 */
				background: -webkit-linear-gradient(left, $color-background-1 75%, $color-background-3 25%); /* Chrome10-25,Safari5.1-6 */
				background: linear-gradient(to right, $color-background-1 75%, $color-background-3 25%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
				filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$color-background-1', endColorstr='$color-background-2',GradientType=0 ); /* IE6-9 */
			}
		}
		@media screen and (max-width: 1024px) {
			padding-top: $padding-medium;
			margin-top: $padding-medium;
			width: 100%;

		}
		@media screen and (min-width: 1025px) {
			padding-top: $padding-big;
			margin-top: $padding-big;
			width: 66.666666%;

		}
	}


/* CENY */

.product__taxbaseprice .value,
.product__taxdiscounted .value,
.product__taxoldprice .value,
.product__baseprice .value,
.product__discounted .value,
.product__oldprice .value {
 	font-weight: 600;
}
.product__taxbaseprice .value,
.product__taxdiscounted .value {
	font-size: 1em;
	display: inline-block;
}
.product__taxbaseprice .legend,
.product__taxdiscounted .legend,
.product__availability .legend {
	display: none;
}
.product__taxbaseprice .price,
.product__taxdiscounted .price {
	font-size: 2em;
	position: relative;
	top: 0.3em;
}
.product__taxbaseprice,
.product__taxdiscounted {
	margin-top: -1.3em;
}
.product__taxbaseprice .tax,
.product__taxdiscounted .tax,
.product__taxoldprice .tax {
	display: none;
}
.product_view .product__taxbaseprice,
.product_view .product__taxdiscounted {
}
.product_view .product__taxbaseprice .value,
.product_view .product__taxdiscounted .value {
	margin-top: -0.3em;
}
.product_view .product__taxoldprice {
}
.product_view .product__taxbaseprice,
.product_view .product__taxdiscounted,
.product_view .product__taxoldprice {
	padding-top: 0.4em;
}

/* FORMULAR */

.product_result .product__buyform,
.product_result .product__nobuyform {
	margin: 2rem 0 0 0;
	position: absolute;
	text-align: center;
	left: 50%;
	clear: both;
	top: 100%;
	transform: translateY(-50%) translateX(-50%);
	margin-top: -50px;

}
.product_result .product__buyform {
	z-index: 2;
	width: 100%;
}
.product_result .product__nobuyform {
	width: 80%;
	padding-top: 5px;
	padding-bottom: 5px;
}
.product_view .product__buyform {
	text-align: left;
	padding-top: $padding-big;
}
.product_view .product__nobuyform {
	padding-top: 8px;
	padding-bottom: 8px;
	margin-top: 2rem;
	@media screen and (min-width: 480px) {
		width: 48%;
		margin-left: 52%;
	}
}
.product__nobuyform {
	color: #888;
	border-style: dashed;
	border-left: none;
	border-right: none;
	box-sizing: border-box;
	line-height: 1.2;
}
.product_result .cartadd {
	margin-left: 10px;
}
.product__buyform .legend {
	display: none;
}
.product_result .product__buyform input {
	@media screen and
	(min-width: 320px) and (max-width: 479px),
	(min-width: 600px) and (max-width: 779px),
	(min-width: 880px) {
		font-size: 1rem;
	}
}
.product_view .product__buyform input {
	@media screen and (min-width: 320px) and (max-width: 479px), (min-width: 600px) {
		font-size: 1rem;
	}
}
.quantity {
	border-color: $color-background-1;
}
.product_result .quantity {
	padding: 8px 5px;
	width: 2rem;
	@media screen and
	(min-width: 320px) and (max-width: 479px),
	(min-width: 600px) and (max-width: 779px),
	(min-width: 880px) {
		padding-left: 8px;
		padding-right: 8px;
		width: 3rem;
	}
}
.product_view .quantity {
	padding: 8px;
	width: 3rem;
	@media screen and (min-width: 480px) and (max-width: 599px) {
		padding-left: 5px;
		padding-right: 5px;
		width: 2rem;
	}
}

.cartadd {
	@extend .link-cta;
}

.product_view .cartadd {
	margin-left: 10px;
}
/* DOSTUPNOSTI */

.product__availability {
	@extend %text-medium;
	.notavailable {
		color: #cf0202;
	}
	.ordered {
		color: #ff803a;
	}
	.stock {
		color: #8cc63e;
	}
	.ontheway {
		color: #029ccf;
	}
}


/* PARAMETRY */


/* Parametry obrázkové */

.product__parameters-icon {
	position: absolute;
	left: 0;
	top: 70px;
	img {
		max-width: none;
		display: block;
	}
}
.product_view .product__parameters-icon {
		height: 3rem;
	img {}
}
.product_result .product__parameters-icon {
	img {}
}
/*.product_result .product__parameters-icon {
	position: absolute;
	top: 180px;
	left: 0;
	width: 100%;
	background: rgba(255,255,255,0.7);
	z-index: 2;
}*/

/* Parametry ve sloupci */

.product_view .product__top {
	margin-bottom: 0.5rem;
}
.product__points .value {
	font-weight: bold;
}


/* Parametry v obsahu */

.product__parameters-text {
	clear: both;
	box-sizing: border-box;
	@media screen and (min-width: 1025px) {
		padding: 20px;
	}
}
.product__parameters-text p {
	margin-bottom: 0.3rem;
}
.product__parameters-text p:nth-of-type(odd) {
	background: $color-params-odd;
}
.product__parameters-text p:nth-of-type(even) {
	background: $color-params-even;
}
.product__parameters-text span {
	@media screen and (max-width: 479px) {
		display: block;
	}
}
.product__parameters-text .legend {
	@media screen and (min-width: 480px) {
		border-right: 1px solid $color-cart-table-border;
		width: 20%;
		min-width: 160px;
		padding-right: 5%;
	}
}
.product__parameters-text .value {
	@media screen and (max-width: 479px) {
		margin-bottom: 10px;
	}
	@media screen and (min-width: 480px) {
		padding-left: 20px;
	}
}
div.product_view .product__parameters-text img {
	max-width: 3rem;
	max-height: none;
}


/* STITKY */

.product__tag--neutral,
.product__tag--low,
.product__tag--medium,
.product__tag--high,
.product__newto {
	position: absolute;
	font-style: normal;
	font-weight: bold;
	text-transform: normal;
	padding: 2px 20px 4px 20px;
	color: #fff;
//	background: #fff;
//	border-radius: 25px 0 25px 0;
	font-size: 0.8rem;
}
.product__tag--neutral {
//	border: 2px solid #ffae2f;
//	color: #ffae2f;
	background: #ffae2f;
}
.product__tag--low {
//	border: 2px solid #da8600;
//	color: #da8600;
	background: #da8600;
}
.product__tag--medium {
//	border: 2px solid #ac4a01;
//	color: #ac4a01;
	background: #ac4a01;
}
.product__tag--high {
//	border: 2px solid #682d01;
//	color: #682d01;
	background: #682d01;
}

/* NOVINKA */

.product__newto {
//	border: 2px solid #2f82ff;
//	color: #2f82ff;
	color: #fff;
	background: #2f82ff;
	top: 0;
	transform: translateX(-100%);
}
.product_result .product__newto {
	left: 100%;
}
.product_view .product__newto {
	left: 48%;
}

/* AKCE */

.product__discount {
	background: $color-background-3;
	border-radius: 50%;
	color: $color-text-contrast;
	font-weight: bold;
}
.product_result .product__discount {
	left: auto;
	right: 10%;
	top: 30px;
	font-size: 0.8rem;

}
.product_view .product__discount {
	left: 48%;
	top: 70px;
	transform: translateX(-100%);
	width: 5rem;
	height: 5rem;
	line-height: 5rem;
	font-size: 1.5rem;
}

// spodní margin zvolených prvků
.product_result {
	.perex,
	.product__taxbaseprice,
//	.product__taxoldprice,
	.product__taxdiscounted,
//	.product__baseprice,
//	.product__oldprice,
//	.product__discounted,
	.product__availability,
	.product__noprice,
	.product__notax {
		margin-bottom: (1rem * 1.125);
	}
}
.product__baseprice ~ .product__taxbaseprice,
.product__taxoldprice ~ .product__taxdiscounted {
//	margin-top: -1.5rem;
}



/* OSTATNÍ */

.product_result .view {
	display: block;
	z-index: 1;
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
}
.product_result .view a {
	width: 100%;
	height: 100%;
	text-indent: -9999px;
	display: block;
}


/* ZKUŠENOST **/

.product__reviews {
	float: right;
	box-sizing: border-box;
	padding: $content-padding;
	position: relative;
	@media screen and (max-width: 1024px) {
		margin: $padding-medium 0;
		width: 100%;
	}
	@media screen and (min-width: 1025px) {
		margin: $padding-big 0;
		top: $padding-big;
		width: 33.33333%;
		&:before {
			content: ' ';
			position: absolute;
			display: block;
			width: 100%;
			height: 1px;
			padding-right: 2px;
			background: $color-background-3; /* FF3.6-15 */
		}
	}
	&--no {
		border: 1px solid $color-banner-2;
		@media screen and (min-width: 1025px) {
			&:before {
				top: -33px;
				left: -1px;
			}
		}
		h2 {
			color: $color-banner-2;
		}
	}
	&--yes {
		background: $color-background-4;
		@media screen and (min-width: 1025px) {
			&:before {
				top: -32px;
				left: 0px;
			}
		}
		.avatar {
			margin-bottom: $padding-medium;
			width: 162px;
			height: 162px;
			-webkit-border-radius: 50%;
			-moz-border-radius: 50%;
			border-radius: 50%;
			background: $color-background-4;
			background: -moz-linear-gradient(top, $color-background-4 50%, $color-background-3 50%); /* FF3.6-15 */
			background: -webkit-linear-gradient(top, $color-background-4 50%, $color-background-3 50%); /* Chrome10-25,Safari5.1-6 */
			background: linear-gradient(to bottom, $color-background-4 50%, $color-background-3 50%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$color-background-4', endColorstr='$color-background-3',GradientType=0 ); /* IE6-9 */
			div {
				display: block;
				width: 150px;
				height: 150px;
				margin: 6px;
				float: left;
				position: relative;
				-webkit-border-radius: 50%;
				-moz-border-radius: 50%;
				border-radius: 50%;
				overflow: hidden;
			}
			@media screen and (max-width: 479px), (min-width: 1025px) and (max-width: 1365px) {
				margin-left: auto;
				margin-right: auto;
			}
			@media screen and (min-width: 480px) and (max-width: 1024px), (min-width: 1366px) {
				float: left;
				margin-right: $padding-medium;
			}
			img {
				min-width: 100%;
				min-height: 100%;
				max-width: 133.3333%;
				max-height: 133.3333%;
				position: absolute;
				left: 50%;
				top: 50%;
				-ms-transform: translateY(-50%) translateX(-50%);
				-webkit-transform: translateY(-50%) translateX(-50%);
				transform: translateY(-50%) translateX(-50%);
				z-index: 1;
			}
		}
		.review {
			font-size: 95%;
		}
		.name {
			font-weight: bold;
			text-align: center;
		}
	}
	.reviews__add {
	text-align: center;
	a {
		max-width: 350px;
		text-decoration: none;
		color: $color-link-contrast;
		border: 1px solid $color-link-contrast;
		display: inline-block;
		padding: $padding-small $padding-medium $padding-small ($padding-medium + 27px);
		font-weight: bold;
		background: url('icon-experience.png') no-repeat $padding-small center;
		&:hover {
			background-image: url('icon-experience-on.png');
			background-color: $color-link-contrast;
			color: $color-text-contrast;
		}
	}
}
.reviews__empty, .review, .name {
	@media screen and (max-width: 479px), (min-width: 1025px) and (max-width: 1365px) {
		text-align: center;
	}
}
.reviews__empty, .name {
	@media screen and (max-width: 479px), (min-width: 1025px) {
		margin-bottom: $padding-big !important;
	}
}
.reviews__empty {
	@media screen and (max-width: 479px), (min-width: 1025px) and (max-width: 1365px) {
		padding-top: 150px;
		background: url('bee-happy-down.png') no-repeat center top;
		background-size: 150px;
	}
	@media screen and (min-width: 480px) and (max-width: 1024px), (min-width: 1366px) {
		padding: 50px 0 50px 150px;
		min-height: 150px;
		box-sizing: border-box;
		background: url('bee-happy-right.png') no-repeat left center;
		background-size: 150px;
	}
}
}
