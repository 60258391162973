@import "_fonts.scss"; // @font-face
@import "_variables.scss"; // definice barev, fontu, okrajů aj,
//@import "_reset.scss"; // negování nativních vlastností
@import "_base.scss"; // body, text, odkazy, zástupné třídy
@import "_grid.scss"; // rozvržení layoutu
@import "_header.scss"; // oblast záhlaví
@import "_slider.scss"; // oblast slideru
@import "_aside.scss"; // oblast postranního sloupce
@import "_content.scss"; // oblast obsahové části
@import "_products.scss"; // oblast obsahové části s produkty
@import "_popup.scss"; // vyskakovací okna
@import "_reports.scss"; // designové informačn í stránky, např. thank you page, 404 aj.
@import "_order.scss"; // košík a objednávka
@import "_homepage.scss"; // oblast titulní strany
@import "_footer.scss"; // oblast zápatí
@import "_other.scss"; // stránkování, formuláře, breadcrumbs aj.
@import "_typified.scss"; // typizované prvky, cookies, tinyMCE aj.
@import "_admin.scss"; // desktop, administrační rozhraní
@import "_extern.scss"; // prvky třetích stran
