
.extensions {
	@extend .wide-box;
	background: $color-background-4;
	position: relative;
	z-index: 1;
}
.extensions-block {
	@extend .simple-box;
	@media screen and (min-width: 480px) {
		display: flex;
	}
}
.extensions h2 {
	@extend %heading-medium;
	text-align: center;
}
.experiences, .poll {
	height: 400px;
	@extend .home-box__item;
	box-sizing: border-box;
	position: relative;
	padding-bottom: 8em; // velikost link__wrapper
	&--active .link__wrapper {

	}

	@media screen and (max-width: 479px) {
		width: 100%;
		margin-bottom: (2 * $font-size);
	}
	@media screen and (min-width: 480px) {
		width: 50%;
		display: flex;
	}
}
.experience__item {
	position: relative;
	z-index: 1 !important;
	box-sizing: border-box;
}
.poll__item {
	position: relative;
	z-index: 2;
	box-sizing: border-box;
}

// ZKUŠENOSTI
.experiences {
	text-align: center;
	overflow: hidden;
	&--active {
		animation: extension-opening ease 0.5s;
		height: 650px;
	}
}
.experience__item {
	padding: 0 10%;
}
.experience__picture {
	margin: $margin-big;
}
.experience__picture a {
	display: inline-block;
}
.experience__picture img {
	background: $color-background-4;
	background: -moz-linear-gradient(top, $color-background-4 50%, $color-background-3 50%); /* FF3.6-15 */
	background: -webkit-linear-gradient(top, $color-background-4 50%, $color-background-3 50%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom, $color-background-4 50%, $color-background-3 50%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$color-background-4', endColorstr='$color-background-3',GradientType=0 ); /* IE6-9 */
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	border-radius: 50%;
	height: (10*$font-size);
	width: (10*$font-size);
	padding: 6px;
	&:after {}
}


// ANKETA
.poll {
	&--active {
		animation: extension-opening ease 0.5s;
		height: 650px;
	}
}
.poll iframe {
	position: absolute;
	top: 0;
	height: 100%;
	width: 100%;
	box-sizing: border-box;
	padding: 3.5em 10% 3.5em 10%;
	z-index: 1;
}
.poll__heading {
	@extend %heading-medium;
	font-weight: 600;
	font-style: italic;
	line-height: 1.2;
}
.poll__item {width: 100%; margin: 15px 0px 5px 0px;}
.poll__item a {display: block; text-decoration: none;}
.answer {color: $color-text-1;}
.percent {display: block; margin-top: 5px; width: 194px; height: 26px; position: relative;}
.percent span {display: inline-block; height: 26px; overflow: hidden;}
.percent span  {line-height: 26px; padding: 0px 0px 0px 10px;}
.percent .back {color: $color-background-1; position: relative; z-index: 1;}
.percent .front {background: $color-background-1; color: $color-text-contrast; left: 0; top: 0; position: absolute; z-index: 2; margin: 0 0px 0px 0px; max-width: 194px;}
.poll__item:hover .answer {color: $color-background-3;}
.poll__item:hover .back {color: $color-background-3;}
.poll__item:hover .front {background: $color-background-3;}
.voted {color: #888; margin: 20px 0px 15px 0px;}
.all {display: none;}


@-webkit-keyframes extension-opening {
	0% {
		height: 400px;
	}
	100% {
		height: 650px;
	}
}
@keyframes extension-opening {
	0% {
		height: 400px;
	}
	100% {
		height: 650px;
	}
}


// RYCHLÉ ODKAZY
.quick {
	background: $color-background-3;
	color: $color-text-contrast;
	font-weight: bold;
	a {
		color: $color-text-contrast;
		text-decoration: none;
	}
}
.quick__newsletter-l, /* 1* */.quick__pharmacy, .quick__partner, .quick__social {
	@extend .simple-box;
	float: left;
	line-height: 32px;
}

// newsletter
.quick__newsletter {
	@media screen and (max-width: 1024px) {
		background-color: $color-background-1;
		float: left;
		width: 100%;
		a {
			display: block;
			box-sizing: border-box;
			text-align: center;
			float: left;
			width: 100%;
			padding: 0 $padding-big;

		}
		span {
			background: url('icon-newsletter-large.png') no-repeat left center;
			padding: $padding-big 0 $padding-big 80px;
			display: inline-block;

		}
	}

	@media screen and (min-width: 1025px) {
		display: none;
	}
}

// lekarny
.quick__newsletter-l, /* 1* */.quick__pharmacy {
	overflow: hidden;
	float: left;
	a {
		position: relative;
		z-index: 2;
	}
}
.newsletter-l__left, /* 1* */.newsletter-l__right, /* 1* */.pharmacy__left, .pharmacy__right {
	box-sizing: border-box;
	position: relative;
	float: left
}
		.newsletter-l__label, /* 1* */.pharmacy__label {
			position: relative;
			z-index: 2;
			display: inline-block;
			background: url('icon-pharmacy-large.png') no-repeat left center;
			padding: $padding-big 0 $padding-big 80px;
			margin-left: 20px;
			margin-top: -$padding-big;
		}
		.newsletter-l__label /* 1* */ {
			background: url('icon-newsletter-large.png') no-repeat left center;
		}

// partner
.quick__partner {
	float: left;
	a {
		display: block;
		height: 32px;
	}
	img {
		margin: -16px 0;
		padding-top: 8px;
	}
}
// socialni site
.quick__social {
	a {
		width: 32px;
		height: 32px;
		display: inline-block;
		background-position: center;
		background-repeat: no-repeat;
		background-size: 32px;
		text-indent: -9999px;
	}
}
	.quick__social .facebook {
		background: url('icon-facebook.png') no-repeat center;
	}
	.quick__social .youtube {
		background: url('icon-youtube.png') no-repeat center;
	}
	.quick__social .google {
		background: url('icon-google.png') no-repeat center;
	}

// < 479 px
@media screen and (max-width: 479px) {
	.quick__newsletter-l, /* 1* */.quick__pharmacy, .quick__partner, .quick__social {
		padding: $padding-big;
	}
	.quick__partner, .quick__social  {
		width: 100%;
	}
	.quick__partner {
		padding-bottom: 0;
	}
	.quick__social {
		padding-bottom: $padding-big * 1.5;
	}
}
// > 480 px
@media screen and (min-width: 480px) {
	.quick__newsletter-l, /* 1* */.quick__pharmacy, .quick__partner, .quick__social {
		padding-top: ($padding-big * 1.5);
		padding-bottom: ($padding-big * 1.5);
	}
	.quick__partner {
		padding-right: 0;

	}
}
// 480 px - 1024 px
@media screen and (min-width: 480px) and (max-width: 1024px) {
	.quick__newsletter-l, /* 1* */.quick__pharmacy, .quick__partner, .quick__social {
		padding-left: $padding-big;
		padding-right: $padding-big;
	}
	.quick__partner, .quick__social {
		width: 50%;
	}
}
// < 599 px
@media screen and (max-width: 599px) {
.newsletter-l__left, /* 1* */.newsletter-l__right, /* 1* */.pharmacy__left, .pharmacy__right {
		width: 100%;
	}
	.pharmacy__label {
		text-align: center;
	}
}
// > 600 px
@media screen and (min-width: 600px) {
	.newsletter-l__left, /* 1* */.newsletter-l__right, /* 1* */.pharmacy__left, .pharmacy__right {
		&:after {
			content: ' ';
			z-index: 1;
			position: absolute;
			height: 0;
			width: 0;
			border: solid transparent;
			pointer-events: none;
			left: 100%;
			top: 50%;
			transform: rotate(-45deg) translateY(-50%) translateX(-50%);
	-webkit-transform: rotate(-45deg) translateY(-50%) translateX(-50%);
		-ms-transform: rotate(-45deg) translateY(-50%) translateX(-50%);
		}
	}
		.newsletter-l__label, /* 1* */.pharmacy__label {
			margin-bottom: -$padding-big;
		}
		.quick__newsletter-l a, /* 1* */.quick__pharmacy a {
			margin-top: -8px;
			margin-bottom: -8px;
		}
}
// 600 px - 639 px
@media screen and (min-width: 600px) and (max-width: 639px) {
	.newsletter-l__left, /* 1* */.pharmacy__left {
		&:after {
			margin-left: -15px;
		}
	}
}
// 600 px - 1024 px
@media screen and (min-width: 600px) and (max-width: 1024px) {
	.newsletter-l__left, /* 1* */.pharmacy__left {
		&:after {
			border-color: $color-background-4;
			border-width: 0 2px 2px 0;
			display: inline-block;
			padding: 40px;
			transform: rotate(-45deg) translateY(-100%);
	-webkit-transform: rotate(-45deg) translateY(-100%);
		-ms-transform: rotate(-45deg) translateY(-100%);
			margin-top: 15px;
		}

	}
		.newsletter-l__right a, /* 1* */.pharmacy__right a {
			margin-right: -$padding-big;
		}
}
// 600 px - 1024 px
@media screen and (min-width: 600px) and (max-width: 1024px) {
	.newsletter-l__left, /* 1* */.newsletter-l__right, /* 1* */.pharmacy__left, .pharmacy__right {
		width: 50%;
	}
}

// < 1024 px
@media screen and (max-width: 1024px) {
	.quick__newsletter-l, /* 1* */.quick__pharmacy, .quick__partner, .quick__social {
		text-align: center;
	}
	.quick__newsletter-l, /* 1* */.quick__pharmacy {
		width: 100%;
		background: $color-background-2;
	}
	.quick__newsletter-l, /* 1* */ {
		background: $color-background-1;

	}
		.quick__social a {
			margin: 0 10px;
		}
}
// > 1025 px
@media screen and (min-width: 1025px) {
	.quick__newsletter-l, /* 1* */.quick__pharmacy {
		background: $color-background-3;
	}
	.quick__partner, .quick__social {
		padding-left: $padding-mobile;
		padding-right: $padding-mobile;
	}
		.newsletter-l__left, /* 1* */.pharmacy__left {
			background: $color-background-2;
			padding: $padding-huge 0;
			margin: (-$padding-huge) 0;
			&:after {
				border-color: $color-background-2;
				border-width: 100px;
				margin-top: -100px;
				margin-left: 0;
			}
		}
		.newsletter-l__left /* 1* */ {
			background: $color-background-1;
			&:after {
				border-color: $color-background-1;
			}
		}
			.newsletter-l__label, /* 1* */.pharmacy__label {
				margin-right: -$padding-big;
			}
}
// 1025 px - 1199 px
@media screen and (min-width: 1025px) and (max-width: 1199px) {
	.quick__newsletter-l, /* 1* */.quick__pharmacy {
		width: 70%;
		a {
			margin-left: 8em;
		}
	}
	.quick__partner, .quick__social {
		padding-left: 0;
		width: 30%;
		padding-top: ($padding-medium);
		padding-bottom: ($padding-medium);

	}
	.quick__partner {
		text-align: center;
		padding-right: $padding-notebook;
	}
	.quick__social {
		text-align: center;
		a {
			margin-left: 10px;
			margin-right: 0;
		}
	}
}
// 1025 px - 1365 px
@media screen and (min-width: 1025px) and (max-width: 1365px) {
	.quick__newsletter-l, /* 1* */.quick__pharmacy {
		padding-left: $padding-notebook;
	}
	.newsletter-l__left, /* 1* */.pharmacy__left {
		padding-left: $padding-notebook;
		margin-left: -$padding-notebook;
	}
	.quick__social {
		padding-right: $padding-notebook;
	}
	.newsletter-l__right a, /* 1* */.pharmacy__right a {
		margin-right: -$padding-notebook;
	}
}
// 1366 px - 1599 px
@media screen and (min-width: 1366px) and (max-width: 1599px) {
	.quick__newsletter-l, /* 1* */.quick__pharmacy {
		padding-left: $padding-desktop;
	}
	.newsletter-l__left, /* 1* */.pharmacy__left {
		padding-left: $padding-desktop;
		margin-left: -$padding-desktop;
	}
	.quick__social {
		padding-right: $padding-desktop;
	}
	.newsletter-l__right a, /* 1* */.pharmacy__right a {
		margin-right: -$padding-desktop;
	}
}

// > 1200 px
@media screen and (min-width: 1200px) {
	.quick__newsletter-l, /* 1* */.quick__pharmacy {
		width: 55%;
		a {
			margin-left: 8em;
		}
	}
	.quick__partner {
		width: 20%;
	}
	.quick__social {
		width: 25%;
		text-align: right;
		direction: rtl;
		overflow: hidden;
	}
	.quick__social a {
		margin-left: 20px;
		margin-right: 0;
	}
}
// 1600 px - 2299 px
@media screen and (min-width: 1600px) and (max-width: 2299px) {
	.quick__newsletter-l, /* 1* */.quick__pharmacy {
		padding-left: $padding-hd;
	}
	.newsletter-l__left, /* 1* */.pharmacy__left {
		padding-left: $padding-hd;
		margin-left: -$padding-hd;
	}
	.quick__social {
		padding-right: $padding-hd;
	}
	.newsletter-l__right a, /* 1* */.pharmacy__right a {
		margin-right: -$padding-hd;
	}
}
// > 2300 px
@media screen and (min-width: 2300px) {
	.quick__newsletter-l, /* 1* */.quick__pharmacy {
		padding-left: $padding-uhd;
	}
	.newsletter-l__left, /* 1* */.pharmacy__left {
		padding-left: $padding-uhd;
		margin-left: -$padding-uhd;

	}
	.quick__social {
		padding-right: $padding-uhd;
	}
		.newsletter-l__right a, /* 1* */.pharmacy__right a {
		margin-right: -$padding-uhd;
	}
}


// COPYRIGHT
.legal {
	@extend %text-small;
	background: $color-background-4;
	color: $color-text-2;
	font-weight: 300;
	position: relative;
	z-index: 2;
}
	.legal__owner {
		background: $color-background-4;
		border: $padding-medium solid $color-background-4;
		-webkit-border-radius: 50%;
		-moz-border-radius: 50%;
		border-radius: 50%;
		float: left;
		margin-top: -$padding-big;
		margin-left: -$padding-medium;
		box-sizing: border-box;
		width: 125px;
	}
	.legal__copy, .legal__links {
			margin: 1em 0;
	}
	.legal__copy {
		@media screen and (min-width: 1025px) {
			float: left;
		}
	}
	.legal__links {
		@media screen and (max-width: 639px) {
			text-align: center;
			clear: both;
		}
		@media screen and (min-width: 1025px) {
			float: right;
		}
	}
	.legal__creator {
		clear: both;
		display: block;
		margin: $padding-big auto;
		width: 200px;
	}