// KOSIK
.cart__products {
}
.cart__products tr, .order__products tr {
	@media screen and (max-width: 479px) {
		position: relative;
		border: 1px solid $color-cart-table-border;
		&:first-child {
			display: none;
		}
	}
}
.cart__products th,
.cart__products td, .order__products th, .order__products td {
	border-color: $color-cart-table-border;
	@media screen and (min-width: 480px) {
		border-color: $color-cart-table-border;
	}
}
.cart__products th, .order__products th {
	background: $color-cart-table-th-bg;
	font-weight: 300;
	font-size: 90%;
}
.cart__products td, .order__products td {
	padding-left: 15px;
	padding-right: 15px;
	@media screen and (max-width: 479px) {
		display: block;
		border: none;
		font-size: 1.125rem;
	}
}
.cart__products td.picture, .order__products td.picture {
	img {
		@media screen and (max-width: 479px) {
			position: absolute;
			height: 100px;
			top: 50%;
			left: 0;
			transform: translateY(-50%);
		}
		@media screen and (min-width: 480px) and (max-width: 1279px) {
			height: 50px;
		}
		@media screen and (min-width: 1280px) {
			height: 70px;
		}
		width: auto;
	}
}
.cart__products td.heading,
.cart__products td.quantity, .order__products td.quantity, .order__products td.heading {
	@media screen and (max-width: 479px) {
		text-align: right;
	}
}
.cart__products td.heading, .order__products td.heading {
		@media screen and (max-width: 479px) {
			width: 100%;
			box-sizing: border-box;
		}
	a {
		color: $color-link-contrast;
	}
}
.cart__products td.quantity, .order__products td.quantity {
	white-space: nowrap;
	input {
		@media screen and (min-width: 480px) and (max-width: 1024px) {
			max-width: 35px;
		}
		@media screen and (max-width: 479px), (min-width: 1025px) {
			max-width: 50px;
		}
	}
}
.cart__products td.availability, .order__products td.availability {
	@media screen and (min-width: 480px) {
		text-align: center;
	}
}
.cart__products td.qtytaxprice, .order__products td.qtytaxprice {
	font-weight: bold;
}
.cart__products td.options a, .order__products td.options a {
	@media screen and (max-width: 479px) {
		margin-bottom: 10px;
		margin-right: 0;
	}
}

// smazane polozky
.cart__products tr.deleted {
	color: $color-cart-table-deleted;
	font-weight: 300;
	td.heading a {
		color: $color-cart-table-deleted;
		font-weight: 300;
		text-decoration: none;
	}
	@media screen and (min-width: 480px) {
		td.picture {
			position: relative;
			z-index: 1;
			&:before {
				content: ' ';
				display: block;
				background: rgba(255,255,255,0.7);
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
				z-index: 2;
			}
		}
	}
	td.qtytaxprice {
		font-weight: 300;
	}
}

// oblast pod tabulkou
p.cart__sumtaxprice {
	.price {
		font-size: 125%;
		font-weight: bold;

	}
}
p.cart__signup, p.cart__signin {}



// OBJEDNAVKA

.order_new form h2 {
	@extend %heading-small;
}

// Progres
.order__progress {
	margin-bottom: 1em;
	display: table;
	width: 100%;
	float: left;
	box-sizing: border-box;
	border: 1px solid $color-background-3;
	overflow: hidden;
	position: relative;
	z-index: 1;
	white-space: nowrap;
}
.order__progress .deactive, .order__progress b, .order__progress a {
  	position: relative;
	box-sizing: border-box;
	display: table-cell;
	font-weight: 600;
	background-repeat: no-repeat;
	box-sizing: border-box;
	@media screen and (max-width: 379px) {
		background-position: center;
		width: 25%;
		border-right: 1px solid $color-background-2;
	}
	@media screen and (max-width: 779px) {
		text-indent: -9999px;
	}
	@media screen and (min-width: 380px) and (max-width: 779px) {
		&:nth-child(3),
		&:nth-child(5),
		&:nth-child(7) {
			width: 27%;
			background-position: 55% center;
		}
		&:nth-child(1) {
			width: 19%;
			background-position: 50% center;
		}
	}
	@media screen and (min-width: 780px) and (max-width: 1024px) {
		&:nth-child(3),
		&:nth-child(5),
		&:nth-child(7) {
			background-position: 1.5em center;
		}
		&:nth-child(3),
		&:nth-child(7) {
			padding-left: 4em;
		}
		&:nth-child(1) {
			background-position: 0.5em center;
			padding-left: 3em;
		}
		&:nth-child(5) {
			padding-left: 4.7em;
		}
	}
	@media screen and (min-width: 1025px) and (max-width: 1365px) {
		&:nth-child(3),
		&:nth-child(5),
		&:nth-child(7) {
			background-position: 3em center;
		}
		&:nth-child(3),
		&:nth-child(7) {
			padding-left: 5em;
		}
		&:nth-child(1) {
			background-position: 2em center;
			padding-left: 4em;
		}
		&:nth-child(5) {
			padding-left: 5.7em;
		}
	}
	@media screen and (min-width: 1366px) {
		&:nth-child(3),
		&:nth-child(5),
		&:nth-child(7) {
			background-position: 4em center;
		}
		&:nth-child(3),
		&:nth-child(7) {
			padding-left: 7em;
		}
		&:nth-child(1) {
			background-position: 3em center;
			padding-left: 6em;
		}
		&:nth-child(5) {
			padding-left: 7.7em;
		}
	}
	@media screen and (min-width: 380px) {
		&:before, &:after {
			border-top-style: solid;
			border-bottom-style: solid;
			border-left-style: solid;
			border-top-color: transparent;
			border-bottom-color: transparent;
			content: " ";
			position: absolute;
			top: 50%;
			width: 0;
			height: 0;
			transform: translateY(-50%) translateX(-50%);
		}
		&:before {
			left: 0;
			border-left-color: $color-background-3;
			z-index: 0;
		}
		&:after {
			z-index: 1;
		}
	}
	@media screen and (max-width: 1279px) {
		padding: 5px;
		&:before, &:after {
			border-top-width: 2em;
			border-bottom-width: 2em;
			border-left-width: 2em;
		}
		&:after {
			right: -1.9em;
		}
	}
	@media screen and (min-width: 1280px) {
		padding-top: 10px;
		padding-bottom: 10px;
		&:before, &:after {
			border-top-width: 3em;
			border-bottom-width: 3em;
			border-left-width: 3em;
		}
		&:after {
			right: -2.9em;
		}
	}
}
.order__progress .deactive, .order__progress a {
	background-color: $color-background-main;
	&:after {
		border-left-color: $color-background-main;
	}
}
.order__progress .deactive {
	color: $color-separator - #222222;
	&.order__progress1 {
		background-image: url('icon-progress-1-span.png');
	}
	&.order__progress2 {
		background-image: url('icon-progress-2-span.png');
	}
	&.order__progress3 {
		background-image: url('icon-progress-3-span.png');
	}
	&.order__progress4 {
		background-image: url('icon-progress-4-span.png');
	}
}
.order__progress b {
	background-color: $color-background-3;
	color: $color-text-contrast;
	&.order__progress1 {
		background-image: url('icon-progress-1-b.png');
	}
	&.order__progress2 {
		background-image: url('icon-progress-2-b.png');
	}
	&.order__progress3 {
		background-image: url('icon-progress-3-b.png');
	}
	&.order__progress4 {
		background-image: url('icon-progress-4-b.png');
	}
	&:after {
		border-left-color: $color-background-3;
	}
}
.order__progress a {
	color: $color-link-contrast;
	text-decoration: none;
	&.order__progress1 {
		background-image: url('icon-progress-1-a.png');
	}
	&.order__progress2 {
		background-image: url('icon-progress-2-a.png');
	}
	&.order__progress3 {
		background-image: url('icon-progress-3-a.png');
	}
	&.order__progress4 {
		background-image: url('icon-progress-4-a.png');
	}
	&:hover {
		text-decoration: underline;
	}
}

.order__progress .order__progress2,
.order__progress .order__progress3,
.order__progress .order__progress4 {
	padding-left: 1.5em;
}
.order__progress .order__progress1,
.order__progress .order__progress2,
.order__progress .order__progress3 {
	padding-right: 1.5em;
}
.order__progress .order__progress1 {
	padding-left: 0.5em;
}
.order__progress .order__progress2 {
}
.order__progress .order__progress3 {
}
.order__progress .order__progress4 {
	@media screen and (max-width: 379px) {
		border: none;
	}
	padding-right: 0.5em
}

.order__progress .order__progress1:before,
.order__progress .order__progress4:after {
	border: none;
}
.order__progress .separator {
	display: none;
}



// Form
.order_new {
	width: 100%;
	.order__products td {
	}
	.formtab {
		padding: 0;
		.label {
		}
		.submit {
			margin-top: $padding-big;
		}
	}
		input.long, select {
			@media screen and (max-width: 519px) {
				width: 80%;
			}
		}
	i {
		@media screen and (max-width: 519px) {
			font-size: 75%;
			display: block;
		}
	}
	@media screen and (max-width: 479px) {
		 .formtab {
		 	.label {

		 	}
			.label, .input {
				width: 100%;
				box-sizing: border-box;
				display: block;
				text-align: left;
			}
			.input {
				padding: 0;
			}
			#mw_tr_addresskind {
				.label, .input {display: inline-block; width: auto; padding: 0;}
			}
		}
		#formtab_0 {
			.label, .input {text-align: center;}
		}
	}

}



// Kontaktni udaje




// Doprava a platba




// Souhrn

.order__payprice {
	padding: $padding-medium;
	font-size: 1.5em;
	border: 1px solid $color-text-1;
	display: inline-block;
	margin: $padding-medium 0;
	.price {
		font-weight: bold;
	}
}
.order__customer {
	margin: $padding-big 0;
}
.order__customer h2 {
	@extend %heading-small;

}

// Thank you page
