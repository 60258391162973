// MAIN

h1.offset-h1 {
	@media screen and (min-width: 1025px) {
		margin-left: $w-thin;
	}
}
.main {
	position: relative;
	z-index: 1;
}
.content {
	overflow: auto;
}

.article_view .picture, .contact_view .picture, .feedback_view .picture, .job_view .picture, .page_view .picture, .reference_view .picture {
	min-width: 25%;
}
.module_view .content {
	box-sizing: border-box;
	@media screen and (min-width: 1025px) {
		padding: 20px;
	}
}
.module_view .content--colored, #tinymce .content--colored {
	@media screen and (max-width: 1024px) {
		padding: 20px $padding-mobile;
		margin: 0 (-$padding-mobile) $font-size (-$padding-mobile);
	}
	@media screen and (min-width: 1025px) {
		padding: 20px;
		margin: 0 -20px $font-size -20px;
	}
	background-color: $color-content;
}

.perex, #tinymce p, .module_view p, .content p {
	@extend %text-medium;
}
.module_view h2, .head2, #tinymce h2, .main h2 {
	@extend %heading-medium;
	font-weight: normal;
	color: $color-link-1;
}
.module_view *+h2, #tinymce *+h2, .content *+h2, .main *+h2 {
	@media screen and (max-width: 1024px) {
		padding-top: (1rem * 2);
	}
	@media screen and (min-width: 1025px) {
		padding-top: (1rem * 2.4);
	}
}
.module_view h3, .head3, .module_result h2, #tinymce h3, .main h3 {
	@extend %heading-small;
	font-weight: normal;
	line-height: 1.2;
}
.module_view *+h3, #tinymce *+h3, .content *+h3 {
	@media screen and (max-width: 1024px) {
		padding-top: (1rem * 1.7);
	}
	@media screen and (min-width: 1025px) {
		padding-top: (1rem * 1.9);
	}
}
h2 a, h3 a {
	text-decoration: none;
}
.main p {

}
.main h2, .main h3 {

}

// seznamy

div.content > ul,
div.content > ol,
div.map_list > ul,
div.map_list > ol,
#tinymce > ul,
#tinymce > ol {
	@extend %text-medium;
	margin: 0 0 1em (((1em / 1.5) + 0.4em) * 2);
}
div.content ul ul,
div.content ol ol,
div.map_list ul ul,
div.map_list ol ol,
#tinymce ul ul,
#tinymce ol ol {
	margin: (1em / 2) 0 (1em / 2) (((1em / 1.5) + 0.4em) * 2);
}

div.content ul li,
div.map_list ul li,
#tinymce ul li {
	list-style: none;
	margin: 0;
}
div.content ul li:before,
div.map_list ul li:before,
#tinymce ul li:before {
	box-sizing: border-box;
	display: inline-block;
	content: "";
	margin: 0 (1em / 1.5) 0.1em (-((1em / 1.5) + 0.4em));
}
div.content > ul > li:before,
div.map_list > ul > li:before,
#tinymce > ul > li:before {
	width: (1em / 2.5);
	height: (1em / 2.5);
	background: $color-link-1;
	border-radius: 50%;

}
div.content ul ul li:before,
div.map_list ul ul li:before,
#tinymce ul ul li:before {
	width: (1em / 2);
	height: (1em / 2);
	color: $color-link-1;
	border-right: 2px solid $color-link-1;
	border-top: 2px solid $color-link-1;
	transform: rotate(45deg);
}


// tabulky
.content table, #tinymce table {
	@extend %text-medium;
	margin: $margin-big;
	margin-top: $padding-medium;
}
#tinymce th, #tinymce td {
	font-size: 1rem;
}
	.content table th,
	.content table td,
	#tinymce table th,
	#tinymce table td {
	}
	.content table td.number, #tinymce table td.number {
		text-align: right;
	}
table.sbordered,
table.sgrid,
#tinymce table.sbordered,
#tinymce table.sgrid {
	width: 100%;
}
table.grid, #tinymce table.grid {
	min-width: 280px;
}
	table.grid th, #tinymce table.grid th {
		padding: $padding-small;
	}
	table.grid td, #tinymce table.grid td {
		padding: 2px $padding-small;
		vertical-align: middle;
	}
table.bordered,
table.sbordered,
#tinymce table.bordered,
#tinymce table.sbordered {
	border-collapse: collapse;
	text-align: center;
}
	table.bordered th,
	table.sbordered th,
	#tinymce table.bordered th,
	#tinymce table.sbordered th {
		background: $color-table-th-bg;
		color: $color-table-th;
		padding: 5px $padding-small;
		text-align: left;
		font-weight: 600;
		border: 1px solid $color-table-th;
		text-align: center;

	}
	table.bordered td,
	table.sbordered td,
	#tinymce table.bordered td,
	#tinymce table.sbordered td {
		border: none;
		padding: 5px $padding-small;
		border: 1px solid $color-table-th;

	}
	table.bordered .odd,
	table.sbordered .odd,
	#tinymce table.bordered .odd,
	#tinymce table.sbordered .odd {
		background-color: $color-table-odd-bg;
	}
	table.bordered .even,
	table.sbordered .even,
	#tinymce table.bordered .even,
	#tinymce table.sbordered .even {
		background-color: $color-table-even-bg;
	}
	table.bordered tr:last-child td,
	table.sbordered tr:last-child td,
	#tinymce table.bordered tr:last-child td,
	#tinymce table.sbordered tr:last-child td {
	}



// VZOREK ZDARMA:

.sample_view {
	& + p {clear: both;}
	.form {
		padding: 0;
	}
		.samples {
			background: $color-banner-1;
			float: left;
			width: 100%;
			padding: 20px;
			box-sizing: border-box;
			margin-bottom: 3em;
			position: relative;
			.product {
				box-sizing: border-box;
					float: left;

				@media screen and (max-width: 479px) {
					width: 100% !important;
					margin-bottom: 1em;
					padding-left: 25px;
					position: relative;
					img {
						display: none;
						width: 30%;
						position: absolute;
						right: 0;
						top: 0;
					}
				}
				@media screen and (min-width: 480px) {
					width: 20%;
					padding: 20px 2%;
					text-align: center;
				}
				@media screen and (min-width: 480px) and (max-width: 779px) {
					min-width: 150px;
					display: inline-block;
				}
				@media screen and (max-width: 479px), (min-width: 780px) {
				}
				&:hover {
					color: $color-link-1;
				}
				label {
					@media screen and (max-width: 479px) {
						display: block;
					}
				}
				input {
					&:checked + label {
						color: $color-link-1;
					}
					@media screen and (max-width: 479px) {
						margin-right: 10px;
						margin-left: -25px;
						float: left;
						+ label img {
							display: none;
							width: 30%;
							position: absolute;
							right: 0;
							top: 50%;
							transform: translateY(-50%);
						}
						&:checked + label {
							img {
								display: block;

							}
						}
					}
					@media screen and (min-width: 480px) {
						margin-bottom: 0.5em;
					}
				}
				h3 {
					@extend %text-big;
					font-style: normal;
					font-weight: normal;
					margin-bottom: 0.2em;

					@media screen and (max-width: 479px) {
						width: 60%;
						float: left;
					}
					@media screen and (min-width: 480px) {
						overflow: hidden;
					}
					@media screen and (min-width: 480px) and (max-width: 1024px) {
						height: 2.8rem;
					}
					@media screen and (min-width: 1025px) {
						height: 4.5rem;
					}
				}
				.baseprice {
					white-space: nowrap;
					@media screen and (max-width: 479px) {
						width: 60%;
						float: left;
						display: block;
					}
					@media screen and (min-width: 480px) {
						margin-bottom: 1em;
						display: block;
					}
				}
				img {
					margin: auto;
					@media screen and (max-width: 479px) {
						display: none;
					}
					@media screen and (max-width: 1279px) {
						max-height: 150px;
					}
					@media screen and (min-width: 1280px) and (max-width: 1479px){
						max-height: 175px;
					}
					@media screen and (min-width: 1480px)  {
						max-height: 200px;
					}
				}
				@media screen and (max-width: 479px) {
					&:first-of-type {
						input:checked + label img {
							transform: none;
							top: 0;
						}
					}
					&:last-of-type {
						margin-bottom: 0;
						input:checked + label img {
							bottom: 0;
							top: auto;
							transform: none;
						}
					}
				}
			}
			&.error {
				background: #ffeeee;
				color: #ee0000;
				border: 1px solid #ee0000;
			}
			p.error {
				width: 100%;
				padding-top: $padding-big;
				clear: both;
			}
		}
		.delivery-note {
			padding-left: 30px;
			background: url('icon-note.png') no-repeat left center;
		}
		.formtab {
			padding: 0;
			td {
				&.label {
					width: auto;
				}
				@media screen and (max-width: 479px) {
					display: block;
					width: 100%;
					padding: 0;
					text-align: left;
				}
			}
			tr:nth-of-type(10) {
				td {
					&.label {
						text-align: left;
					}
				}
			}
			tr:nth-of-type(11), tr:nth-of-type(12) {
				td {
					label {
						display: inline;
					}
				}
			}
		/*	tr:nth-of-type(13), tr:nth-of-type(14) {
				td {
					text-align: center;
				}
			}*/
			input, select {
				border-color: $color-link-2;
				font-weight: bold;
				padding: $padding-small;
				line-height: 1;
				margin-bottom: 0;
			}
			.input {
				@media screen and (min-width: 480px) {
					padding: $padding-small;
				}
			}
			.label, td:first-child {
				white-space: nowrap;
				label {
					line-height: 1;
					margin-top: (($padding-small * 2) + 1px);
				}
			}
			.g-recaptcha > div {
				margin: 0;
			}
		}
}

// SOUVISEJICI PRODUKT

.article_view .products {
	width: 100%;
	float: left;
	clear: both;
	box-sizing: border-box;
	@media screen and (min-width: 1025px) {
		display: flex;
-webkit-display: flex;
		flex-wrap: wrap;
-webkit-flex-wrap: wrap;
		object-fit: contain;
	}
}
.article_view .product {
	float: left;
	box-sizing: border-box;
	padding: 20px 0;
	margin: 0;
	border-top: 1px solid $color-link-2;
	width: 100%;
	&:last-of-type {
		border-bottom: 1px solid $color-link-2;
		margin-bottom: $padding-medium;
	}
	.picture {
		min-width: 0;
		text-align: center;
		width: 200px;
		@media screen and (max-width: 479px) {
			max-width: 50%;
		}
		@media screen and (min-width: 480px) {
			height: 200px;

		}
		img {
			max-width: 100%;
			@media screen and (max-width: 479px) {
				height: 150px;
			}
			@media screen and (min-width: 480px) {
				height: 200px;
			}
		}
	}
	h3 {
		clear: none;
		margin-top: $padding-medium;
		a {
			text-decoration: underline;
		}
	}
	p.view {
		text-align: center;
		@media screen and (max-width: 639px) {
			float: none;
			margin: 0 auto;
		}
		@media screen and (min-width: 640px) {
			float: right;
		}
		a {
			@extend .link-cta;
			padding-left: 50px;
			padding-right: 50px;
			@media screen and (min-width: 640px) {
			}
		}
	}
}