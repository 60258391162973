// ADMINISTRAČNÍ ROZHRANÍ

form.form {
	padding-bottom: $font-size;
}
p.add, p.options {
	margin: ($font-size / 2) 0;
}
form.form.tabs h2 {
	padding-top: $font-size;
}
p.add a {
	background: #0088ff;
	font-weight: bold;
	padding: $padding-small $padding-medium;
	box-shadow: 2px 3px 3px rgba(0,31,125,1);
	&:hover {
		background: #0088ff - #333333;
	}
	&:after {
		content: '';
		float: left;
		width: 25px;
		height: 25px;
		margin-right: $padding-medium;
		background: url('icon-plus.png') no-repeat center;
	}
}