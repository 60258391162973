// ASIDE
 .aside {
 	@media screen and (min-width: 1025px) and (max-width: 1139px) {
//		padding-top: 2.6*$line-height*$font-size;

	}
 	@media screen and (min-width: 1140px) {
//		padding-top: 4.4*$line-height*$font-size;
	}
 }
.partition {
	margin: $margin-medium;
}


// SUBMENU
.submenu__heading {
	@extend %heading-small;
	font-weight: normal;
	margin-bottom: 10px;
}
@media screen and (min-width: 1025px) {
	.submenu * {
		box-sizing: border-box;
	}
		.submenu li {
			position: relative;
		}
			.submenu a {
				color: $color-text-1;
				text-decoration: none;
			}
			.submenu a:hover {
				color: $color-link-2;
				text-decoration: underline;
			}
			.submenu ul ul {
				font-size: $font-size;
				font-weight: normal;
			}
			.submenu li.active > span {
				background: $color-background-2;
				color: #fff;
			}
/* 1. úroveň */
	.submenu > ul {
		border-top: 1px solid $color-background-2;
	}
			.submenu > ul > li > a,
			.submenu > ul > li.active > a,
			.submenu > ul > li.active > span {
				font-weight: bold;
				padding: ($font-size / 2);
			}
			.submenu li a,
			.submenu li.active > span {
				display: block;
			}
			.submenu > ul > li > a,
			.submenu > ul > li.active > span {
				border-bottom: 1px solid $color-background-2;
			}

/* 2. úroveň */
				.submenu > ul > li > ul {
					border-bottom: 1px solid $color-background-2;
					background: $color-background-4;
				}
				.submenu > ul > li > ul > li > a,
				.submenu > ul > li > ul > li.active > a,
				.submenu > ul > li > ul > li.active > span {
					padding: ($font-size / 4) ($font-size / 4) ($font-size / 4) ($font-size * 2);
					&:before {
						content: '';
						display: inline-block;
						position: absolute;
						left: $font-size;
						top: $font-size * 0.85;
						width: $font-size / 4;
						height: $font-size / 4;
						border-top: 2px solid;
						border-right: 2px solid;
						transform: rotate(45deg);
					}
				}
				.submenu > ul > li > ul > li.active > a {
					&:before {
						transform: rotate(135deg);

					}
				}
/* 3. úroveň */
					.submenu > ul > li > ul > li > ul > li a,
					.submenu > ul > li > ul > li > ul > li.active > a,
					.submenu > ul > li > ul > li > ul > li.active > span {
						padding: ($font-size / 8) ($font-size / 8) ($font-size / 8) ($font-size * 3);
					}
}