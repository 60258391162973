
// OBECNÉ

.report__area {
	border: none;
	margin: 0;
	position: relative;
	@media screen and (max-width: 1024px) {
		padding-top: 3vh;
		padding-bottom: 3vh;
	}
	@media screen and (min-width: 1025px) {
		padding-top: 5vh;
		padding-bottom: 5vh;
	}
}
.report__top,
.report__middle,
.report__bottom {
	@extend .report__area;
	display: block;
	text-align: center;
	margin-left: -11px;
	margin-right: -11px;
	padding-left: 11px;
	padding-right: 11px;
	* {
		position: relative;
		z-index: 2;
	}
	span {
		display: block;
	}
}
.report__top {
	margin-top: -11px;
	z-index: 2;
	&--arrowed {
		&:before {
			content: '';
			width: 100px;
			height: 100%;
			display: block;
			position: absolute;
			bottom: -1px;
			left: 50%;
			transform: translateX(-50%);
			z-index: 1;
		}
	}
	&--blank {
		margin-bottom: -11px;
	}
	.top__text, .text__top {
		&--large {
			@extend %heading-medium;
			line-height: 1.2;
			//margin-bottom: 0;
			font-weight: 600;
		}
		&--small {
			@extend %text-big;
			font-weight: 300;
			//margin-bottom: 0;
			//margin-bottom: 5vh;
		}
	}
}
.report__bottom {
	z-index: 1;
	margin-bottom: -11px;
	&--arrowed {
		padding-top: 70px;
		&:before {
			content: '';
			display: block;
			position: absolute;
			top: 0;
			left: 50%;
			z-index: 1;
			height: 0;
			width: 0;
			border: solid transparent;
			pointer-events: none;
			transform: rotate(-45deg);
	-webkit-transform: rotate(-45deg);
		-ms-transform: rotate(-45deg);
			border-width: 35px;
			margin-top: -35px;
			margin-left: -35px;
		}
	}
	&--empty {
		padding-top: 0;
		background: $color-background-main !important;
	}
	.bottom__text, .text__bottom {
		&--large {
			@extend %heading-small;
			font-weight: bold;
			//margin-bottom: 0;
		}
		&--small {
			@extend %text-medium;
			font-weight: 300;
		}
	}
}
[class*="report"] [class*="text"] {
	margin-bottom: 0.25em;
	&:last-child {
		margin-bottom: 0;
	}
}
.success, .failure, .caution, .empty, .advice {padding: 10px;} // viz p.succ aj. v cms.css
.success, .failure {
	.report__top {
		.top__text, .text__top {
			&--large {
				color: $color-text-contrast;
			}
			&--small {
				color: $color-text-contrast;
			}
		}
	}
	.report__bottom {
		.bottom__text, .text__bottom {
			&--large {
				color: $color-text-contrast;
			}
			&--small {

			}
		}
	}
}
.success {
	.report__top, .report__middle {
		background: $color-background-3;
		&--arrowed {
			&:before {
				background: $color-background-2;
			}
		}
	}
	.report__bottom {
		background: $color-banner-2;
		&--arrowed {
			&:before {
				background: $color-background-2;
			}
		}
		.bottom__text, .text__bottom {
			&--small {
				color: $color-text-1;
			}
		}
	}
}
.failure, .caution {
	.report__top {
		background: $color-banner-2;
		&--arrowed {
			&:before {
				border-color: $color-banner-dark;
			}
		}
	}
	.report__bottom {
		background: $color-background-3;
		&--arrowed {
			&:before {
				background: $color-background-3;
			}
		}
		.bottom__text, .text__bottom {
			&--small {
				color: $color-text-contrast;
			}
		}
	}
}
.empty, .advice {
	.report__top {
		background: $color-banner-1;
		&--arrowed {
			&:before {
			}
		}
	}
	.report__bottom {
		background: $color-background-main;
		padding-bottom: 0;
		margin-bottom: -30px;
		&--arrowed {
			&:before {
			}
		}
		.bottom__text, .text__bottom {
			&--small {
				color: $color-text-1;
			}
		}
	}
}
.report__facebook {
	&--img {
		display: block;
		position: relative;
		z-index: 1;
		margin: 0 auto $padding-medium auto;
		width: 50px;
		height: 50px;
		background: url("icon-facebook-blue.png") no-repeat center;
		text-indent: -9999px;
	}
	&--link {
		@extend .link-cta;
		position: relative;
		z-index: 1;
		background: #2a5297;
		color: $color-text-contrast !important;
	-webkit-box-shadow: 2px 2px 2px 0px rgba(0,0,0,0.7);
	   -moz-box-shadow: 2px 2px 2px 0px rgba(0,0,0,0.7);
			box-shadow: 2px 2px 2px 0px rgba(0,0,0,0.7);
		&:hover {
			background-color: $color-link-contrast;
	-webkit-box-shadow: 2px 2px 2px 0px rgba(179,0,90,1);
	   -moz-box-shadow: 2px 2px 2px 0px rgba(179,0,90,1);
			box-shadow: 2px 2px 2px 0px rgba(179,0,90,1);
		}
	}
}
.report__top,
.report__middle,
.report__bottom {
	img {
		max-height: 200px;
		margin-bottom: $padding-medium;
	}
}
.report__image {
	background-color: #fff;
	background-repeat: no-repeat;
	background-position: center;
	border-radius: 50%;
	display: inline-block;
	margin-right: auto;
	margin-left: auto;
/*	@media screen and (max-width: 639px) {
		width: 100px;
		height: 100px;
		-webkit-box-shadow: 1px 2px 4px 1px rgba(0,0,0,0.5) inset;
		   -moz-box-shadow: 1px 2px 4px 1px rgba(0,0,0,0.5) inset;
				box-shadow: 1px 2px 4px 1px rgba(0,0,0,0.5) inset;
	margin-bottom: 5vh;

	}*/
	@media screen /*and (min-width: 640px) */and (max-width: 1024px) {
		width: 150px;
		height: 150px;
		background-size: 150px;
		-webkit-box-shadow: 1px 2px 5px 1px rgba(0,0,0,0.5) inset;
		   -moz-box-shadow: 1px 2px 5px 1px rgba(0,0,0,0.5) inset;
				box-shadow: 1px 2px 5px 1px rgba(0,0,0,0.5) inset;
		margin-bottom: 3vh;

	}
	@media screen and (min-width: 1025px) {
		width: 200px;
		height: 200px;
		background-size: 200px;
		-webkit-box-shadow: 1px 3px 5px 2px rgba(0,0,0,0.5) inset;
		   -moz-box-shadow: 1px 3px 5px 2px rgba(0,0,0,0.5) inset;
				box-shadow: 1px 3px 5px 2px rgba(0,0,0,0.5) inset;
		margin-bottom: 5vh;
	}
}
.report__image--sad {
	background-image: url('bee-sad-down.png');
}
.report__image--surprised {
	background-image: url('bee-surprised.png');
}
.report__image--happy {
	background-image: url('bee-happy-forward.png');
}
.report__image--annoyed {
	background-image: url('bee-annoyed-down.png');
}
.report__image--proud {
	background-image: url('bee-proud.png');
}
.report__link--cta {
	@extend .link-cta--secondary;
}

// KOŠÍK

.cart_view {
// Prazdny kosik
	.empty {
		@extend .report__area;
		.top__text--large {
			color: $color-text-1;
		}
	}

// Vlozeni do kosiku
	.succes {
		padding: 0;
		.bottom__text--large {
			color: $color-text-1;
		}
		.bottom__text--small {
			@extend %text-big;
			color: $color-text-contrast;
		}
		.report__bottom img {
			width: 100px;
		}
	}
}


// OBJEDNÁVKA

.order_new {
	.bottom__text--large {
	}

// Uspesna objednavka

	.succes {
		.bottom__text--small {
		}
	}
}
