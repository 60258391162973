// ----- zástupné třídy START -----
%link-transition { // animace odkazů a tlačítek
		box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	-webkit-backface-visibility: hidden;
		 	backface-visibility: hidden;
	-moz-osx-font-smoothing: grayscale;
	-webkit-transition: all 0.5s ease;
			transition: all 0.5s ease;
	-webkit-transition-property: color, background-color, background-image, box-shadow, border;
			transition-property: color, background-color, background-image, box-shadow, border;
}
%heading-big {
	@media screen and (max-width: 479px) {
		font-size: (1em * 1.6);
		margin-bottom: (0.5em);
		font-weight: 600;
	}
	@media screen and (min-width: 480px) and (max-width: 1139px) {
		font-size: (1em * 2);
		margin-bottom: (0.75em);
		font-weight: 600;
	}
	@media screen and (min-width: 1140px) {
		font-size: (1em * 3);
		margin-bottom: (0.75em);
		font-weight: 600;
	}
}
%heading-medium {
	@media screen and (max-width: 479px) {
		font-size: (1em * 1.4);
		margin-bottom: (0.4em);
		font-weight: 300;
	}
	@media screen and (min-width: 480px) and (max-width: 1139px) {
		font-size: (1em * 1.8);
		margin-bottom: (0.5em);
		font-weight: 300;
	}
	@media screen and (min-width: 1140px) {
		font-size: (1em * 2.4);
		margin-bottom: (0.5em);
		font-weight: 300;
	}
}
%heading-small {
	@media screen and (max-width: 479px) {
		font-size: (1em * 1.2);
		margin-bottom: (0.4em);
	}
	@media screen and (min-width: 480px) and (max-width: 1139px) {
		font-size: (1em * 1.3);
		margin-bottom: (0.5em);
	}
	@media screen and (min-width: 1140px) {
		font-size: (1em * 1.9);
		margin-bottom: (0.5em);
	}
}
%text-big {
	@media screen and (max-width: 1139px) {
		font-size: (1em * 1.1);
		margin-bottom: (0.5em);
	}
	@media screen and (min-width: 1140px) {
		font-size: (1em * 1.5);
		margin-bottom: (0.5em);
	}
}
%text-medium {
	@media screen and (max-width: 1139px) {
		font-size: (1em);
		margin-bottom: (0.5em);
	}
	@media screen and (min-width: 1140px) {
		font-size: (1em * 1.125);
		margin-bottom: (0.5em);
	}
}
%text-small {
	@media screen and (max-width: 1139px) {
		font-size: (1em * 0.7);
		margin-bottom: (1em);
	}
	@media screen and (min-width: 1140px) {
		font-size: (1em * 0.8);
		margin-bottom: (1em);
	}
}
%text-tiny {
	@media screen and (max-width: 1139px) {
		font-size: (1em * 0.6);
		margin-bottom: (1em);
	}
	@media screen and (min-width: 1140px) {
		font-size: (1em * 0.7);
		margin-bottom: (1em);
	}
}
// ----- zástupné třídy END -----
body, #tinymce {
	font-family: $font-family;
	font-weight: $font-weight;
	line-height: $line-height;
	color: $color-text-1;
	@media screen and (max-width: 1139px) {
		font-size: ($font-size);
	}
	@media screen and (min-width: 1140px) {
		font-size: ($font-size * 1.125);
	}
}
h1, .head1 {
	@extend %heading-big;
	line-height: 1.2;
	//color: $color-link-1;
}
h2 {
	line-height: 1.2;
}
h1, h2, h3, h4, h5, h6 {

}
b, strong {font-weight: 600;}
.link { // běžné textové odkazy, např. v obsahu a v zápatí
	@extend %link-transition;
	&:link, &:visited {
		color: $color-link-1;
	}
 	&:hover, &:active {
 		color: $color-link-2;
 	}
}
a {
	@extend .link;
}
.link-view, .link-cta {
}

.link-cta {
	@extend %link-transition;
	@extend %content;
	font-weight: bold;
	display: inline-block;
	text-decoration: none;
	text-align: center;
	cursor: pointer;
	border: none;
	@media screen and (max-width: 1024px) {
		font-size: (1rem);
	}
	@media screen and (min-width: 1025px) {
		font-size: (1rem * 1.125);
	}
}
.link-cta/*--primary*/ { // call to action tlačítka 1. úrovně, příp. submit odesílacího formuláře
	color: $color-text-contrast !important;
	background-color: $color-link-contrast;
-webkit-box-shadow: 2px 2px 2px 0px rgba(179,0,90,1);
   -moz-box-shadow: 2px 2px 2px 0px rgba(179,0,90,1);
		box-shadow: 2px 2px 2px 0px rgba(179,0,90,1);
	&:hover {
		color: $color-text-contrast;
		background-color: $color-link-1;
	-webkit-box-shadow: 2px 2px 2px 0px rgba(0,113,31,1);
	   -moz-box-shadow: 2px 2px 2px 0px rgba(0,113,31,1);
			box-shadow: 2px 2px 2px 0px rgba(0,113,31,1);
	}
}
.link-cta--secondary {// call to action druhé úrovně
		color: $color-text-contrast !important;
		background-color: $color-background-1;
-webkit-box-shadow: 2px 2px 2px 0px rgba(0,113,31,1);
   -moz-box-shadow: 2px 2px 2px 0px rgba(0,113,31,1);
		box-shadow: 2px 2px 2px 0px rgba(0,113,31,1);
	&:hover {
		color: $color-text-contrast;
		background-color: $color-banner-3;
	-webkit-box-shadow: 2px 2px 2px 0px rgba(47,0,0,1);
	   -moz-box-shadow: 2px 2px 2px 0px rgba(47,0,0,1);
			box-shadow: 2px 2px 2px 0px rgba(47,0,0,1);
	}
}
/*.home__link-all {
	clear: both;
}*/
/*.image, img {
	height: auto;
	max-width: 100%;
}*/
/*.image-wide { // obrázky zpravidla v divu .picture, příp. slidy aj.
	width: 100%;
	height: auto;
}*/
/*.picture { // zástupné obrázky
	height: 20rem;
	overflow: hidden;
	margin-bottom: 2rem;
	img {
		width: 100%;
		height: auto;
	}
}*/
//.picture a {display: inline-block;}
.perex img, .content img, .homepage img { // obsahové obrázky
	margin-bottom: $line-height;
}
.highlighted {
	background-color: #f5f5f5;
}
.black {
	font-weight: 800;
}
.uppercase {
	text-transform: uppercase;
}