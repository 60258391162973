@font-face {
    font-family: 'TitilliumWeb';
    src: url('/mw1/themes/1/fonts/TitilliumWeb-Black.ttf');
		src: local('TitilliumWeb'), url('/mw1/themes/1/fonts/TitilliumWeb-Black.ttf') format('truetype');
		font-weight: 800;
		font-style: normal;
}
@font-face {
    font-family: 'TitilliumWeb';
    src: url('/mw1/themes/1/fonts/TitilliumWeb-Bold.ttf');
		src: local('TitilliumWeb'), url('/mw1/themes/1/fonts/TitilliumWeb-Bold.ttf') format('truetype');
		font-weight: bold;
		font-style: normal;
}
@font-face {
    font-family: 'TitilliumWeb';
    src: url('/mw1/themes/1/fonts/TitilliumWeb-BoldItalic.ttf');
		src: local('TitilliumWeb'), url('/mw1/themes/1/fonts/TitilliumWeb-BoldItalic.ttf') format('truetype');
		font-weight: bold;
		font-style: italic;
}
@font-face {
    font-family: 'TitilliumWeb';
    src: url('/mw1/themes/1/fonts/TitilliumWeb-SemiBold.ttf');
		src: local('TitilliumWeb'), url('/mw1/themes/1/fonts/TitilliumWeb-SemiBold.ttf') format('truetype');
		font-weight: 600;
		font-style: normal;
}
@font-face {
    font-family: 'TitilliumWeb';
    src: url('/mw1/themes/1/fonts/TitilliumWeb-SemiBoldItalic.ttf');
		src: local('TitilliumWeb'), url('/mw1/themes/1/fonts/TitilliumWeb-SemiBoldItalic.ttf') format('truetype');
		font-weight: 600;
		font-style: italic;
}
@font-face {
    font-family: 'TitilliumWeb';
    src: url('/mw1/themes/1/fonts/TitilliumWeb-Regular.ttf');
		src: local('TitilliumWeb'), url('/mw1/themes/1/fonts/TitilliumWeb-Regular.ttf') format('truetype');
		font-weight: normal;
		font-style: normal;
}
@font-face {
    font-family: 'TitilliumWeb';
    src: url('/mw1/themes/1/fonts/TitilliumWeb-Italic.ttf');
		src: local('TitilliumWeb'), url('/mw1/themes/1/fonts/TitilliumWeb-Italic.ttf') format('truetype');
		font-weight: normal;
		font-style: italic;
}
@font-face {
    font-family: 'TitilliumWeb';
    src: url('/mw1/themes/1/fonts/TitilliumWeb-Light.ttf');
		src: local('TitilliumWeb'), url('/mw1/themes/1/fonts/TitilliumWeb-Light.ttf') format('truetype');
		font-weight: 300;
		font-style: normal;
}
@font-face {
    font-family: 'TitilliumWeb';
    src: url('/mw1/themes/1/fonts/TitilliumWeb-LightItalic.ttf');
		src: local('TitilliumWeb'), url('/mw1/themes/1/fonts/TitilliumWeb-LightItalic.ttf') format('truetype');
		font-weight: 300;
		font-style: italic;
}