// COOKIES
.cookies, #cookies_close {// lišta + okno
//	background: $color-primary;
//	color: $font-color-contrast;
	position: fixed;
	text-align: center;
	z-index: 99;
	box-sizing: border-box;
}
.cookies { // lišta
	width: $w-wide;
	padding: 0.5em 1em;
	bottom: 0;
}
	.cookies a { // odkazy
//		color: $font-color-contrast;
	}
	a.cookies_agree, #cookies_close a { //tlačítka "Souhlasím" a "OK" na liště
//		background: $background-color-separating;
//		color: $color-primary;
//		border: 2px solid $background-color-separating;
		display: inline-block;
		font-weight: bold;
		text-decoration: none;
		padding: 2px 1em;
		&:hover {
//			background: $background-color-contrast;
//			color: $font-color-contrast;
//			border-color: $background-color-separating;
		}
	}
	a.cookies_agree { // tlačítko "Souhlasím" na liště - odsazení od textu
		margin: 0 10px;
	}
	.cookies a.close { // křížek na liště
		background: url('../common/icons/cookies_close.png') no-repeat center;
		cursor: pointer;
		display: block;
		position: absolute;
		right: 10px;
		top: 10px;
		width: 20px;
		height: 20px;
	}
#cookies_close { // okno
//	border: 1px solid $background-color-separating;
	padding: 2em;
	width: $w-wide * 0.5;
	left: $w-wide * 0.25;
	top: $w-wide * 0.20;
	@media screen and (max-width: 640px) {
		width: $w-wide * 0.8;
		left: $w-wide * 0.1;
	}
}
	#cookies_close a { // tlačítko "OK" v okně
		margin-top: 1em;
	}



// TinyMCE
.mce-fullscreen {
	position: relative;
	z-index: 99;
}