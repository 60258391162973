/* Mob menu */
.slicknav_menu {
	display: none;
}
@media screen and (max-width: 1024px) {
	#menu {
		display: none;
	}
	.slicknav_menu {
		display: block;
	}
}
.slicknav_menu * {
	box-sizing: border-box;
}
// HAMBURGER
	.slicknav_btn { // odkaz na zobrazení mobilního menu
		position: absolute;
		display: block;
		cursor: pointer;
		height: 40px;
		width: 40px;
		right: 0;
		text-align: center;
		color: $color-text-1;
		text-decoration: none;
		z-index: 2;
	}
.navigation__web {
	&.navigation__empty
	.slicknav_btn {
		@media screen and (max-width: 479px) {
			top: -50px;
		}
		@media screen and (min-width: 480px) and (max-width: 1024px) {
			top: -48px;
		}
	}
	&.navigation__full
	.slicknav_btn {
		@media screen and (max-width: 1024px) {
			top: -40px;
		}
	}
}
.navigation__shop {
	.slicknav_btn {
		@media screen and (max-width: 479px) {
			top: -50px;
		}
		@media screen and (min-width: 480px) and (max-width: 1024px) {
			top: -48px;
		}
	}
}
		.slicknav_menutxt { // zpravidla text MENU
			display: none;
		}
		.slicknav_icon { // oblast ikony
			display: inline-block;
			margin-top: 50%;
			transform: translateY(-50%);
		}
			.slicknav_icon-bar { // jednotlivé čáry v ikoně
				background-color: $color-background-1;
				border-radius: 3px;
				display: block;
				width: 30px;
				height: 6px;
				margin: 4px 0;
			}
		.slicknav_open { // ikona hamburgeru při otevřeném menu
			//background: $color-background-4;
		}
			.slicknav_open .slicknav_icon-bar { // jednotlivé čáry v ikoně při otevřeném menu

			}

// NAVIGACE
	.slicknav_nav { // oblast menu
	//	overflow: hidden;
		text-align: center;
		width: 100%;
		z-index: 3;
		display: block;
		position: relative;
		background: $color-background-main;
		border: 1px solid $color-link-contrast;
	}
		.slicknav_nav ul {
			display: block;
			list-style: none;
			overflow: hidden;
			padding: 0;
			margin: 0;
			width: 100%;
		}
			.slicknav_nav li {
				font-weight: normal;
			}
				.slicknav_nav li li {
					text-transform: none;
				}
				.slicknav_nav li a,
				.slicknav_nav li.active span {
					display: inline-block;
					text-decoration: none;
				}
			.slicknav_row {
				display: block;
			}
			.slicknav_item { // řádek položky s potomky s odkazem
				cursor: pointer;
			}
				.slicknav_item a { // textová část odkazu položky s potomky
					display: inline;
					padding: 0;
				}
				.slicknav_arrow { // šipka pro rozevření další úrovně
					font-size: 1rem;
					margin: 0 -1.3rem 0 0.3rem;
				}
				.slicknav_nav .slicknav_item a {
					display: inline;
					padding: 0;
					margin: 0;
				}
				.slicknav_nav .slicknav_row {
					display: block;
				}
				.slicknav_nav li a {
					 display: block;
				}
				.slicknav_nav li.slicknav_parent {padding: 0;}
				.slicknav_nav .slicknav_parent-link a {
					display: inline;
					padding: 0;
					margin: 0;
				}
			.slicknav_nav li a, .slicknav_nav li.active span {
				text-decoration: none;
			}
				.slicknav_nav li li a {
				}
			.slicknav_nav li a {
				padding: 10px 20px;
				display: block;
			}
			.slicknav_nav li.active {
				padding: 10px 20px;
			}
				.slicknav_nav li.active > span,
				.slicknav_nav li.active > a {
					font-weight: bold;
				}
				.slicknav_nav li.active > span {
					padding: 0 20px;
					display: inline-block;
				}
					.slicknav_nav li li a,
					.slicknav_nav li.active li.active > span {
						padding: 5px 20px;
					}
					.slicknav_nav li li a {
					}
					.slicknav_nav li.active li.active > span,
					.slicknav_nav li.active li.active > a,
					.slicknav_nav li.active li.active > a > span,
					.slicknav_nav li.active li.active > a > a > span {
					}
						.slicknav_nav li li li a,
						.slicknav_nav li.active li.active li.active > span {

						}
							.slicknav_nav li li li li a,
							.slicknav_nav li.active li.active li.active li.active > span {

							}

/* responsiveslides.css */

.centered-btns_tabs {margin-top: 10px;text-align: center;}
	.centered-btns_tabs li {display: inline; margin-right: 0; position: relative;}
	.centered-btns_tabs a {display: inline-block; height: 12px;margin: 0 6px 0 0; overflow: hidden; text-indent: -9999px;width: 12px;}
	.centered-btns_tabs a {
		/*background-color: $color-text-2;border: 2px solid $color-background-1;*/
		-webkit-border-radius: 50%;
		-moz-border-radius: 50%;
		border-radius: 50%;
}

//	.centered-btns_here a {background-color: $color-background-1;border-color: $color-text-1;}
//.centered-btns_tabs a {background-position: -5px -124px;}
//.centered-btns_here a {background-position: -24px -124px;}


#picViewer {}
.viewer__arrow {
	border: solid $color-background-1;
	border-width: 0 4px 4px 0;
	display: inline-block;
	padding: 20px;
	position: absolute;
	top: 50%;
	z-index: 4;
	box-sizing: border-box;
	cursor: pointer;
}
.arrow__prev {
	left: 0;
	transform: translateX(50%) translateY(-50%) rotate(135deg);
}
  .arrow__prev:hover {
}
.arrow__next {
	right: 0;
	transform: translateX(-50%) translateY(-50%) rotate(-45deg);
}
  .arrow__next:hover {
}



//


.fb-like {
	table {
		width: 100%;
	}
	iframe {
		width: 100%;
		height: auto;
	}
	button {
		display: block;
		z-index: 2;
		position: relative;
		height: 100px;
	}
}