%search-icon { // definice vzhledu prvku, použitého pro více účelů (ikonka s lupou)
	display: inline-block;
	text-indent: -9999px;
	border: none;
}
.header {
	position: relative;
	z-index: 2;
}
.header hr {
	border: 0;
	height: 1px;
	background: $color-background-3;
	clear: both;
	margin-bottom: 0;
	margin-top: $padding-medium;
}

.header__area { // oblast headeru obsahující logo, vyhledávání, menu
	padding-top: $padding-small;
	padding-bottom: 0;
	position: relative;
}
	.logo {
		float: left;
		position: relative;
		z-index: 2;
		@media screen and (max-width: 1024px) {
			height: 80px;
		}
		@media screen and (min-width: 480px) {
			margin-bottom: -10px;
		}
	}
		.logo__image {
			width: auto;
			@media screen and (max-width: 1024px) {
				height: 80px;
			}
			@media screen and (min-width: 1025px) {
				max-width: 100px;
			}
		}
	.phone {
		float: right;
		background: url('icon-phone.png') no-repeat left center;
		font-weight: 600;
		line-height: 40px;
		a {
			text-decoration: none;
			color: $color-text-1;
			&:hover {
				text-decoration: underline;
			}
		}
		@media screen and (max-width: 379px) {
			font-size: (1rem * 1.5);
		}
		@media screen and (max-width: 479px) {
			margin-top: 40px;
		}
		@media screen and (max-width: 639px) {
			background-size: 24px;
			padding-left: 32px;
		}
		@media screen and (min-width: 380px) and (max-width: 639px) {
			font-size: (1rem * 1.5);
		}
		@media screen and (min-width: 640px) {
			font-size: (1rem * 1.9);
			background-size: 32px;
			padding-left: 45px;
		}
		@media screen and (min-width: 480px) and (max-width: 1024px) {
			float: left;
			margin-left: 35px;
			margin-top: 24px;
		}
		@media screen and (min-width: 1025px) {
			margin-top: ($font-size);
		}
	}


// MENU / UŽIVATEL / PREPÍNAČ JAZYKŮ
.navigation {
	@media screen and (max-width: 479px) {
		top: (($padding-medium * 1.25) + 40px);
	}
	@media screen and (min-width: 480px) and (max-width: 1024px) {
		top: (($padding-medium * 1.25) + 60px);
	}
	@media screen and (max-width: 1024px) {
		position: absolute;
		right: $padding-medium;
		padding-left: $padding-medium;
		box-sizing: border-box;
		max-width: 320px;
	}
	@media screen and (min-width: 1025px) {
		float: left;
		margin-top: -(3 * $font-size);
		width: 100%;
		padding-left: 120px;
		box-sizing: border-box;
		&.navigation__shop {
			padding-right: 200px;
		}
	}
	@media screen and (min-width: 1025px) and (max-width: 1719px) {
		&.navigation__web.navigation__full {
			padding-right: 240px;
		}
		&.navigation__empty {
			padding-right: 10px;
		}
	}
	@media screen and (min-width: 1720px) {
		&.navigation__web.navigation__full {
			padding-right: 390px;
		}
		&.navigation__empty {
			padding-right: 40px;
		}
	}
}

// MENU
	.mainmenu {
		z-index: 1;
		@media screen and (min-width: 1025px) {
			clear: left;
			float: left;
			font-weight: 600;

		}
	}
		.mainmenu__area {
		}
			.mainmenu__home { //oprava chybného výpisu menu - duplikování titulní strany
				float: left;
			}
// Desktopové jednořádkové menu
			.mainmenu__area li ul {// skrytí podúrovní menu vypsaných kvůli mobilnímu JS menu //.mainmenu__item ul
				display: none;
			}
			.mainmenu__area li {
				@media screen and (min-width: 1025px) and (max-width: 1279px) {
				}
				@media screen and (min-width: 1140px) {
					margin-right: $padding-small;
				}
			}
			.mainmenu__area li, .mainmenu__area li a {//.mainmenu__item, .mainmenu__link
				display: inline-block;
			}
			.mainmenu__area li a {//.mainmenu__link
				text-decoration: none;
				color: $color-text-1;
				display: inline-block;
				&:hover {
					text-decoration: underline;
					color: $color-link-1;
				}
				@media screen and (min-width: 1025px) and (max-width: 1279px) {
					padding: $padding-small;
				}
				@media screen and (min-width: 1280px) {
					padding: $padding-small $padding-medium;
				}
			}
			.mainmenu__area .mainmenu__item--active, .mainmenu__area li.active {//
					padding: $padding-small $padding-medium;
					color: $color-link-1;
//				color: $color-primary;
					a {//.mainmenu__link
						margin: $padding-small * -1 $padding-medium * -1;
//						color: $color-primary;
					}
				}

// PŘEPÍNAČ JAZYKŮ
.head__currency {
	@media screen and (min-width: 1025px) {
		position: absolute;
		//right: 10px;
		//padding-bottom: 5px;
		//padding-right: 10px;
		//widh: 80px;
		width: auto;
		//text-align: right;
		//top: ($padding-medium * 1.8);
		top: 5px;
	/*	&:after {
			content: '';
			display: block;
			border: solid $color-background-1;
			border-width: 0 2px 2px 0;
			display: inline-block;
			padding: 2px;
			position: absolute;
			//bottom: 0;
			top: 50%;
			//left: 50%;
			right: 0;
			z-index: 4;
			//margin-left: -2px;
			margin-top: -2px;
			box-sizing: border-box;
		}*/
	}
	@media screen and (min-width: 1025px) and (max-width: 1365px) {
		right: $padding-notebook;
	}
	@media screen and (min-width: 1366px) and (max-width: 1599px) {
		right: $padding-desktop;
	}
	@media screen and (min-width: 1600px) and (max-width: 2299px) {
		right: $padding-hd;
	}
	@media screen and (min-width: 2300px) {
		right: $padding-uhd;
	}
}
.currency__switch {
	@media screen and (min-width: 1025px) {
		line-height: 1;
		a, span {
			margin-left: $padding-medium;
		}
		a {
			color: $color-link-1;
		}
		span {
			color: $color-text-1;
			font-weight: bold;
		}
	}
}





// UŽIVATELSKÉ ROZHRANÍ

.customer {
	position: relative;
	z-index: 3;
	@media screen and (min-width: 1025px) {
		margin-right: (($font-size * 1.125 * 1.5) * 1.125);
		float: right;
		max-width: 300px;
	}
}
	.customer__name,
	.customer__desktop {
		cursor: pointer;
		a, > span {
			display: block;
			color: $color-link-contrast;
			text-decoration: none;
			padding: $content-padding;
		}
		> a, > span {
			@media screen and (min-width: 1025px) and (max-width: 1365px) {
				text-indent: -9999px;
			}
		}
	}
	.customer__name {
		a, span {
			@media screen and (min-width: 1025px) {
				padding-left: $padding-medium * 2;
				background-position: left ($padding-small + 1px);
				background-repeat: no-repeat;
				&:hover {
					text-decoration: underline;
				}
			}
		}
		span {
			max-width: 150px;
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
		}
	}
	.customer__name--login > a {
		background-image: url('icon-user-off.png');
	}
	.customer__name--logged > span {
		background-image: url('icon-user-on.png');
	}
	.customer__hidden {
		@media screen and (min-width: 1025px) {
			display: none;
		}
	}
	.customer__desktop {
		background: $color-background-main;
		box-sizing: border-box;
		text-align: center;
		min-width: 100%;
		@media screen and (min-width: 1025px) {
			position: absolute;
			top: -1px;
			right: -1px;
			border: 1px solid $color-link-contrast;
		}
		ul {
			white-space: nowrap;
		}
		li {
			padding: 0 $padding-medium;
			a {
				text-decoration: none;
				@media screen and (min-width: 1025px) {
					color: $color-text-1;
				}
			}
			&.customer__account, &.customer__orders {
				a {
					border-bottom: 1px solid $color-link-2;
					padding-left: 0;
					padding-right: 0;
				}
			}
			span {
				@media screen and (min-width: 1025px) {
					display: block;
					cursor: pointer;
					background: url('icon-user-on.png') no-repeat $padding-medium center;
					padding-left: $padding-medium * 3;
				}
			}
		}
		a {
			display: block;
			text-decoration: none;
			&:hover {
				text-decoration: underline;
			}
		}
	}


// mobilní prvky

@media screen and (max-width: 1024px) {
.desktop-only {
	display: none;
}
	.customer__loginlink {
		margin: $padding-small 0;
		width: 80%;
	}
	.customer__name--login {
		padding: $content-padding;
	}
	.customer__desktop {
		background: $color-background-2;
		a {
			color: $color-text-contrast;
		}
	}

}

// desktopové prvky

@media screen and (min-width: 1025px) {
.mobile-only {
	display: none;
}
	.customer__switch {
		display: block;
		color: $color-link-contrast;
		text-decoration: none;
		padding: $content-padding;
	}
		.customer__loginform {
			padding: $padding-small $padding-medium $padding-medium $padding-medium;
			label, input {
				margin: $margin-small;
				width: 100%;
			}
			label {
				font-size: 90%;
				white-space: nowrap;
			}
			.submit {
				padding: ($padding-small / 2) $padding-medium;
				margin: $padding-small 0 $padding-small (-1px);
			}
			a {
				color: $color-link-contrast;
				white-space: nowrap;
				&.password_lost {
					font-size: 90%;
					text-decoration: underline;
				}
				&.customer__signup {
					font-size: 110%;
				}
			}
			span {
				display: block;
				text-indent: -9999px;
				cursor: pointer;
				background: url('icon-user-off.png') no-repeat center top;
				padding-bottom: $padding-medium;
			}
			.textfiled {
				padding: 2px 5px;
				text-align: center;
				font-size: 100%;
				font-weight: 300;
				margin-bottom: 15px;
			}
			.customer__signup {
				font-weight: 600;
			}
			.password_lost {
				margin: $margin-small;
			}
		}

}

// MOBILNÍ NAVIGACE max 1024 px
@media screen and (max-width: 1024px) {
	.head__currency {
		border-top: 1px solid $color-link-1;
		border-bottom: 1px solid $color-link-1;
		a, span {
			display: inline-block !important;
			padding: 0 !important;
			width: 40px;
			height: 40px;
			line-height: 40px;
		}
		span {
			font-weight: bold;
			color: $color-link-1;
		}
		a {
			color: $color-text-1;
			text-decoration: underline !important;
		}
	}
		.mainmenu__area li {
			width: 100%;
		}
}
// KOŠÍK
	.cart a {
		text-decoration: none;
		display: inline-block;
		box-sizing: border-box;
		position: relative;
		span {
			position: relative;
			z-index: 2;
		}
	}
		.cart__text {
			white-space: nowrap;
		}

	a.cart__empty,
	a.cart__web {
		&:hover {
			text-decoration: underline;
		}
	}
	a.cart__empty,
	a.cart__shop {
		color: $color-link-contrast;
		background-image: url('icon-cart.png');
		background-repeat: no-repeat;
		width: initial; // oprava CACH
		padding-left: initial; // oprava CACH
		@media screen and (max-width: 479px) {
			top: 10px;
		}
		@media screen and (min-width: 480px) and (max-width: 1024px) {
			top: 32px;
		}
		@media screen and (max-width: 1024px) {
			position: absolute;
			right: ($padding-medium + 120px);
			width: 40px;
			height: 40px;
			background-position: center;
		}
 		@media screen and (min-width: 1025px) {
			padding: $padding-small $padding-medium;
 			padding-left: 50px;
 			background-position: 10px center;
 			float: right;
			margin-top: -(3 * $font-size);
		}
	}
	a.cart__shop, a.cart__web {
		.cart__sum, .cart__count, .cart__price {
			font-weight: bold;
		}
	}
	a.cart__full {
		@media screen and (min-width: 1025px) and (max-width: 1365px) {
			margin-right: (($font-size * 2 * 1.2) / 2);
		}
		@media screen and (min-width: 1366px) {
			margin-right: ((($font-size * 1.125 * 1.5) * 1.125) / 2);
		}
	}
	a.cart__empty {
		text-indent: -9999px;
		@media screen and (min-width: 1025px) {
			margin-right: (-$padding-medium);
			background-position: center;
			width: 28px;
		}
	}
	a.cart__shop.cart__full {
		display: block;
		@media screen and (min-width: 1025px) {
 			width: 200px;
		}
		.cart__sum, .cart__count, .cart__price  {
			color: $color-text-1;
		}
		&:after {
			@extend %link-transition;
		}
		@media screen and (max-width: 1024px) {
			.cart__count, .cart__price, .cart__text, .cart__for {
				display: none;
			}
			.cart__sum {
				background: $color-background-1;
				display: block;
				position: relative;
				width: 25px;
				height: 25px;
				border-radius: 50%;
				color: $color-text-contrast;
				line-height: 25px;
				text-align: center;
				font-weight: bold;
			}
			.cart__amount {
				position: absolute;
				right: -5px;
				top: 6px;
				z-index: 2;
			}
		}
		@media screen and (min-width: 1025px) {
			line-height: 1;
			border: 1px solid $color-link-contrast;
			border-right: none;
			&:after {
				content: '';
				position: absolute;
				display: block;
				background-color: $color-background-main;
				border-style: solid;
				border-color: $color-link-contrast;
				border-top-width: 1px;
				border-right-width: 1px;
				border-left: none;
				border-bottom: none;
				transform: rotate(45deg);
				@media screen and (max-width: 1139px) {
					height: round((($font-size * $line-height) + (2 * $padding-small)) * 0.72);
					width: round((($font-size * $line-height) + (2 * $padding-small)) * 0.72);
					top: round(($padding-small * 0.72) - 1);
					right: round(-(((($font-size * 1.125 * $line-height) + (2 * $padding-small)) / 2) * 0.72));
				}
				@media screen and (min-width: 1140px) {
					height: round((($font-size * 1.125 * $line-height) + (2 * $padding-small)) * 0.72);
					width: round((($font-size * 1.125 * $line-height) + (2 * $padding-small)) * 0.72);
					top: round($padding-small * 0.72);
					right: round(-(((($font-size * 1.125 * $line-height) + (2 * $padding-small)) / 2) * 0.72) - 1px);
				}
			}
			.cart__text {
				margin-top: (-$padding-small / 2);
				display: block;
			}
			&:hover {
				background-color: $color-link-contrast;
				background-image: url('icon-cart-on.png');
				color: $color-text-contrast;
				&:after {
					background-color: $color-link-contrast;
				}
			}
		}
		.cart__amount {
			margin-bottom: (-$padding-small / 2);
			display: block;
		}

	}

	a.cart__web.cart__full {
		padding: $padding-small 0 $padding-small 40px;
		color: $color-text-contrast;
		background: $color-link-contrast;
		z-index: 2;
		text-align: center;
		float: right;
		box-sizing: border-box;
		@media screen and (max-width: 479px) {
			line-height: 1.2;
			top: $padding-small;
			span.cart__text, span.cart__amount {
				display: block;
			}
		}
		@media screen and (min-width: 480px) and (max-width: 1024px) {
			font-size: $font-size * 1.125;
			top: $padding-medium;
		}
		@media screen and (max-width: 1024px) {
			position: absolute;
			right: ($font-size * 2 * 1.2);
		}
		@media screen and (min-width: 1025px) and (max-width: 1719px) {
			margin-top: -((3 * $font-size) * 1.125);
			max-width: 220px;
			font-size: $font-size;
			line-height: 1.2;
		}
		@media screen and (min-width: 1720px) {
			margin-top: -(3 * $font-size);
		}
		&:before {
			content: '';
			position: absolute;
			display: block;
			left: 0;
			top: 50%;
			transform: translateX(-50%) translateY(-50%);
			background: url('bee-profile.png') no-repeat left center;
			@media screen and (max-width: 1719px) {
				width: 60px;
				height: 60px;
				background-size: 60px;
			}
			@media screen and (min-width: 1720px) {
				width: 70px;
				height: 70px;
			}
		}
		&:after {
			content: '';
			position: absolute;
			display: block;
			transform: translateX(50%) translateY(-50%) rotate(45deg);
			background: $color-link-contrast;
			top: 50%;
			right: 0;
			z-index: 1;
			@media screen and (max-width: 479px), (min-width: 1025px) and (max-width: 1719px) {
				height: ($font-size * 2 * 1.2);
				width: ($font-size * 2 * 1.2);
			}
			@media screen and (min-width: 480px) and (max-width: 1024px), (min-width: 1720px) {
				height: (($font-size * 1.125 * 1.5) * 1.125);
				width: (($font-size * 1.125 * 1.5) * 1.125);
			}
		}
	}
// VYHLEDÁVÁNÍ
.search {
	position: absolute;
	@media screen and (max-width: 1024px) {
		top: 0;
		left: 0;
		width: 100%;
	}
	@media screen and (min-width: 1025px) {
		left: 50%;
		top: 0;
		transform: translateX(-50%);
	}
}
	.search__open, .search__close {
		@media screen and (max-width: 1024px) {
			display: block;
			cursor: pointer;
			position: absolute;
		}
		@media screen and (min-width: 1025px) {
			display: none;
		}
	}
	.search__open {
		@extend %search-icon;
		@media screen and (max-width: 479px) {
			top: 10px;
		}
		@media screen and (min-width: 480px) and (max-width: 1024px) {
			top: 32px;
		}
		@media screen and (max-width: 1024px) {
			background: url('icon-search.png') no-repeat center;
			width: 40px;
			height: 40px;

			right: ($padding-medium + 60px);
		}
	}
	.search__area {
		@media screen and (max-width: 1024px) {
			display: none;
		}
		@media screen and (min-width: 1025px) {
			display: block;
		}
	}
		.search__form {
			background: $color-background-main;
			@media screen and (min-width: 1025px) {
				margin-top: (1.5 * $font-size);
			}
		}
			.search__label {
				display: none;
			}
			.search__input {
				@extend %text-small;
				margin: 0;
				display: inline-block;
				background: $color-background-main;
				color: $color-text-2;
				height: $line-height;
				line-height: $line-height;
				font-weight: 300;
				font-family: $font-family;
				font-size: 1rem;
				border: none;
				height: $line-height * 1.5rem;
				@media screen and (max-width: 1024px) {
					padding: 0;
					width: 100%;
					font-size: 1.2em;
				}
				@media screen and (min-width: 1025px) {
					border-bottom: 2px solid $color-background-1;
					width: (14 * $font-size);
				}
			}
			.search__submit {
				@extend %search-icon;
				float: right;
				background: url('icon-search.png') no-repeat center;
				display: inline-block;
				cursor: pointer;
				padding: 0;
				width: $line-height * 2rem;
				height: $line-height * 2rem;
				margin-top: -7px;
				@media screen and (max-width: 1024px) {
					position: absolute;
					right: $padding-medium;
					@media screen and (max-width: 479px) {
						top: $padding-medium;
					}
					@media screen and (min-width: 480px) {
						top: $padding-small;
					}
				}
			}
.search.displayed {
	.search__close {
		@media screen and (max-width: 1024px) {
			top: 0;
			right: $padding-medium;
			height: $padding-medium;
			line-height: $padding-medium;
			padding: 0 10px;
			font-size: 0.8em;
			z-index: 2;
			background: $color-link-contrast;
			color: $color-text-contrast;
			}
		}
		.search__area {
			@extend .simple-box;
			@media screen and (max-width: 1024px) {
				display: block;
				width: 100vw;
				max-width: 420px;
				box-sizing: border-box;
				position: fixed;
				right: 0;
				top: 0;
				z-index: 3;
				background: $color-background-main;
			}
		}
			.search__form {
				@extend .simple-box;
				@media screen and (max-width: 1024px) {

					border: 1px solid $color-link-contrast;
					background: $color-background-main;
					z-index: 1;
					@media screen and (min-width: 480px) {
						padding-top: $padding-small;
						padding-bottom: $padding-small;
					}
				}
			}
}

/* webovy kosik */
.header--cart {
	@media screen and (max-width: 639px) {
		padding-top: 70px;
	}
	@media screen and (min-width: 640px) and (max-width: 1024px) {
		padding-top: 50px;
	}
	.navigation {
		@media screen and (max-width: 479px) {
			top: (($padding-medium * 1.25) + 40px + 55px);
		}
		@media screen and (min-width: 480px) and (max-width: 639px) {
			top: (($padding-medium * 1.25) + 60px + 55px);
		}
		@media screen and (min-width: 640px) and (max-width: 1024px) {
			top: (($padding-medium * 1.25) + 60px + 35px);
		}
	}
	.search__open {
		@media screen and (max-width: 479px) {
			top: 20px + 55px;
		}
		@media screen and (min-width: 480px) and (max-width: 639px) {
			top: 40px + 55px;
		}
		@media screen and (min-width: 640px) and (max-width: 1024px) {
			top: 40px + 35px;
		}
	}
}