// BARVY
$color-link-1: #00a650; // barva odkazů, tlačítek a jiných prvků
$color-link-2: #8cc63e; // barva odkazů, tlačítek a jiných prvků ve stavu hover, active, nebo s odlišnou barvou
$color-link-contrast: #ef1d8a; // barva CTA, barva kontrastní s color-link-1 a 2
$color-text-1: #000; // základní barva obsahového textu
$color-text-2: #212121; // sekundární barva textu, např. patička aj.
$color-text-contrast: #ffffff; // kontrastní barva textu
$color-text-colored: #da8600; // barevný text, např. nadpisy aj.
$color-background-main: #ffffff; // hlavní barva pozadí, zejména body
$color-background-1: #00a650; // jedna z barev pozadí, nejtmavší
$color-background-2: #7db631; // jedna z barev pozadí, tmavší
$color-background-3: #8cc63e; // jedna z barev pozadí, střední
$color-background-4: #f4fee6; // jedna z barev pozadí, světlejší
$color-separator: #d9d9d9;
$color-content: #eff7e6;
$color-table-th-bg: #d5e7bd;
$color-table-odd-bg: #eff7e6;
$color-table-even-bg: #eaf6db;
$color-table-th: #57821f;
$color-params-odd: #e8e8e8;
$color-params-even: #f4f3f3;
$color-cart-table-th-bg: #f3f3f3;
$color-cart-table-border: #cdcdcd;
$color-cart-table-deleted: #929292;
$color-banner-1: #f3f5df;
$color-banner-2: #da8600;
$color-banner-3: #642400;
$color-banner-4: #3e2801;
$color-banner-dark: #9b5801;

$font-family: 'TitilliumWeb', arial, helvetica, sans-serif;
$font-size: 16px; // 1.25em = 20px, základní velikost fontu, doporučená velikost 1em až 1.5em ()
$line-height: 1.5;
$font-weight: 300;
$margin-medium: 0 0 $font-size / 2 0; // základní margin pod jednotlivými bloky, zpravidla 1.5násobek mezer mezi řádky
$margin-big: 0 0 $font-size 0; // zmenšený margin
$margin-small: 0 0 $font-size / 4 0; // zmenšený margin

$w-crop: 100%; // momezení maximální šířky vnitřního layoutu
$w-wide: 100%; // šířka celého webu, 100% nemá okraje
$w-thin: 25%; // šířka postranního sloupce
$w-medium: $w-wide - $w-thin; // šířka obsahové části, rozdíl šířky celého webu a postranního sloupce

$padding-medium: $font-size; // základní velikost paddingu
$padding-big: $padding-medium * 2; // velikost paddingu zpravidla pro levý a pravý okraj
$padding-small: $padding-medium / 2; // velikost paddingu zpravidla pro prvky s potřebou menších okrajů, např. tlačítka, položky v menu aj.
$padding-huge: $padding-medium * 4; // velikost paddingu layoutu, ovlivňuje mainmenu__area
$padding-mobile: $padding-medium;
$padding-notebook: $padding-huge;
$padding-desktop: $padding-huge * 1.5;
$padding-hd: $padding-huge * 3;
$padding-uhd: $padding-huge * 6;

$layout-padding: $padding-big; // padding prvků layoutu
$content-padding: $padding-small $padding-medium; // padding prvků obsahu

$popup-height: 100vh;
$popup-mascot-height: $popup-height * 0.2;
$popup-padding-top: $popup-mascot-height / 1.5;
$popup-padding-bottom: $popup-padding-top / 3;
$popup-area-height: $popup-height - $popup-padding-top - $popup-padding-bottom;
$popup-bottom-height: $popup-padding-top;
$popup-top-height: $popup-area-height - $popup-bottom-height;
$popup-heading-size: $popup-height / 15;
$popup-cta-size: 2vh;
