// POPUP OBECNE

/* pozadi */
.popup {
	position: fixed; /* Stay in place */
	z-index: 100; /* Sit on top */
	box-sizing: border-box;
	left: 0;
	top: 0;
	width: 100vw; /* Full width */
	height: $popup-height; /* Full height */
	background-color: rgba(0,0,0,0.75); /* Black w/ opacity */
	padding-top: $popup-padding-top;
	padding-bottom: $popup-padding-bottom;
	@media screen and (max-width: 1024px) {
		padding-left: 5vw;
		padding-right: 5vw;
	}
	@media screen and (min-width: 1025px) {
		padding-left: 10vw;
		padding-right: 10vw;
	}
}
	/* okno */
	.popup-area {
		position: relative;
		text-align: center;
		background-color: $color-background-main;
		margin: auto;
		width: 100%;
		height: $popup-area-height;
		max-width: 1000px;
		box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
		-webkit-animation-name: animatetop;
		animation-name: animatetop;
		-webkit-animation-duration: 0.4s;
		animation-duration: 0.4s;

	}
		/* animace okna */
		@-webkit-keyframes animatetop {
			from {top:-300px; opacity:0}
			to {top:0; opacity:1}
		}

		@keyframes animatetop {
			from {top:-300px; opacity:0}
			to {top:0; opacity:1}
		}
		.popup-top {
			position: relative;
			box-sizing: border-box;
			height: $popup-top-height;
			z-index: 2;
			padding-left: 5vw;
			padding-right: 5vw;
			padding-top: $popup-mascot-height / 2;
			@media screen and (max-width: 1366px) {
				padding-bottom: $popup-cta-size * 2;
			}
			@media screen and (min-width: 1367px) {
				padding-bottom: $popup-cta-size * 3;
			}
			&:before {
				content: '';
				background: $color-background-main url('bee-happy-down.png') no-repeat center top;
				border-radius: 50%;
				position: absolute;
				z-index: 1;
				top: 0;
				left: 50%;
				transform: translateX(-50%) translateY(-50%);
				-webkit-transform: translateX(-50%) translateY(-50%);
				-ms-transform: translateX(-50%) translateY(-50%);
				background-size: $popup-mascot-height;
				width: $popup-mascot-height * 1.2;
				height: $popup-mascot-height * 1.2;
				padding: $line-height;
			}
		}
			.popup-cross {
				cursor: pointer;
				position: absolute;
				top: 0;
				right: 0;
				z-index: 3;
				color: #ccc;
				font-size: 150%;
				line-height: 30px;
				background: $color-background-main;
				width: 40px;
				height: 40px;
				font-weight: bold;
				border-radius: 50%;
				transform: translateY(-50%);
				-webkit-transform: translateY(-50%);
				-ms-transform: translateY(-50%);
			}
			.popup-heading {
				color: $color-text-1;
				line-height: 1.2;
				position: relative;
				z-index: 2;
				font-weight: 600;
				margin-bottom: $popup-heading-size / 2;
				.text__colored {
					color: $color-banner-2;
				}
				@media screen and (max-width: 479px) {
					font-size: $popup-heading-size * 0.75;
				}
				@media screen and (min-width: 480px) and (max-width: 1024px) {
					font-size: $popup-heading-size * 0.88;
				}
				@media screen and (min-width: 1024px) {
					font-size: $popup-heading-size;
				}
				@media screen and (min-width: 1367px) {
					> span {
						display: block;
					}
				}
			}
			.popup-link {
				@extend .link-cta;
				@extend .link-cta--secondary;
				font-size: 1em;
				padding: ($popup-cta-size / 2) ($popup-cta-size * 3);
				position: absolute;
				bottom: -($popup-cta-size);
				left: 50%;
				transform: translateX(-50%);
				-webkit-transform: translateX(-50%);
				-ms-transform: translateX(-50%);
			}
		.popup-bottom {
			background: $color-banner-2;
			height: $popup-bottom-height;
			position: relative;
			z-index: 1;
		}
		.popup-close {
			font-size: 0.8em;
			padding: 2px 5px;
			cursor: pointer;
			padding: 1px 5px;
			border: 1px solid $color-text-contrast;
			display: inline-block;
			position: absolute;
			transform: translateX(-50%);
			top: $popup-bottom-height / 2;
			left: 50%;
			color: $color-text-contrast;
		}

// VZOREK ZDARMA

$sample-item-height: $popup-top-height - $popup-mascot-height - ($popup-heading-size * 1.5) - ($popup-cta-size);
$sample-name-size: $popup-heading-size / 2;
$sample-name-height: $sample-name-size * 2 * 1.2;
$sample-picture-height: $sample-item-height - $sample-name-height;
$sample-image-height: $sample-picture-height * 0.9;

.sample {
	@extend .popup;
	visibility: hidden;
	-webkit-animation-name: popupdisplay;
	animation-name: popupdisplay;
	-webkit-animation-duration: 0.4s;
	animation-duration: 0.4s;
	animation-delay: 30s; // musí být stejné jako v JS
}
/*	@-webkit-keyframes popupdisplay {
		from {visibility: hidden;}
		to {visibility: visible;}
	}

	@keyframes popupdisplay {
		from {visibility: hidden;}
		to {visibility: visible;}
	}*/
	.sample__area {
		@extend .popup-area;
		animation-delay: 30s; // musí být stejné jako v JS
		.viewer__arrow {
			@media screen and (max-width: 599px) {
				top: 60%;
			}
		}
	}
		.sample__close {
			&--top {
				@extend .popup-cross;
			}
			&--bottom {
				@extend .popup-close;
			}
		}
		.sample__body {
			@extend .popup-top;
			padding-left: 60px;
			padding-right: 60px;
		}
			.sample__heading {
				@extend .popup-heading;
				margin-left: -40px;
				margin-right: -40px;
				@media screen and (min-width: 600px) {
					white-space: nowrap;
				}
			}
			#picViewer {
				height: $sample-item-height;
				overflow: hidden;
			}
			.sample__items {
			//	display: flex;
				overflow: hidden;
 				box-sizing: border-box;
				position: relative;
				width: 100%;
				@media screen and (max-width: 1366px) {
					height: $sample-item-height;
				}
				@media screen and (min-width: 1367px) {
					height: $sample-item-height - $popup-cta-size;
				}
			}
			.sample__item {
				float: left;
				text-align: center;
				box-sizing: border-box;
				width: 25vh;
			}
			.sample__picture {
				overflow: hidden;
				height: $sample-picture-height - $popup-cta-size;
				img {
					width: 100%;
				}
			}
			.sample__name {
				font-size: $sample-name-size;
				font-weight: 600;
				max-width: 90%;
				margin: auto;
				line-height: 1.2;
				height: $sample-name-height;
				overflow: hidden;
				a {
					color: $color-text-1;
					text-decoration: none;
				}
			}
			.sample__cta {
				@extend .popup-link;
				white-space: nowrap;
			}
		.sample__footer {
			@extend .popup-bottom;
		}

// NEWSLETTER

.newsletter {
	@extend .popup;
	display: none;
}
.newsletter.displayed {
	display: block;
}
	.newsletter__area {
		@extend .popup-area;
	}
		.newsletter__close {
			&--top {
				@extend .popup-cross;
			}
			&--bottom {
				@extend .popup-close;
			}
		}
		.newsletter__body {
			@extend .popup-top;
		}
			.newsletter__heading {
				@extend .popup-heading;
				.text__colored, .text__normal {
					display: block;
				}
				.uppercase {
					@media screen and (min-width: 1025px), (orientation: portrait) {
						display: block;
					}
				}
				.text__colored {
					@media screen and (max-width: 1024px), (max-width: 1365px) and (orientation: landscape) {
						margin-bottom: 3vh;
						padding-top: 3vh;
					}
					@media screen and (min-width: 1025px) and (orientation: portrait), (min-width: 1366px) and (orientation: landscape) {
						margin-bottom: 5vh;
						padding-top: 5vh;
					}

				}

			}
			.newsletter__form {
				@media screen and (max-width: 479px) {
					padding: 0;
				}
				@media screen and (max-width: 1024px), (max-width: 1365px) and (orientation: landscape) {
					padding: 3vh 0 0 0;
				}
				@media screen and (min-width: 1025px) and (orientation: portrait), (min-width: 1366px) and (orientation: landscape) {
					padding: 5vh 0 0 0;
				}
				input[type="text"] {
					width: 100%;
					max-width: 400px;
					min-width: 200px;
					padding: 1vh 3vh;
					box-sizing: border-box;
					color: #ccc;
				}

			}
			.newsletter__input {
				margin-bottom: 1vw;
			}
			.newsletter__label {
					display: none;
				}
			.newsletter__agreement {
				display: block;
				font-size: 0.9em;
				label {display: initial;}
			}
			.newsletter__cta {
				@extend .popup-link;
				white-space: nowrap;
				cursor: pointer;
			}
		.newsletter__footer {
			@extend .popup-bottom;
		}