// ----- zástupné třídy START -----
%content { // padding prvků obsahu
	padding: $content-padding;
	box-sizing: border-box;
}
%info__box { // blok textu ve slideru a v mapě
	width: $w-wide;
	z-index: 2;
	@media screen and (max-width: 1024px) {
		position: relative;
		padding: 0;
	}
	@media screen and (min-width: 1025px) {
		position: absolute;
		left: 0;
		top: 0;
	}
	@media screen and (min-width: 1600px) {
		top: 1em;
	}
}
%info__content { // blok textu ve slideru a v mapě - vnitřní oblast
	position: relative;
	width: $w-wide;
	@media screen and (max-width: 1024px) {
		padding: 0 !important;
	}
}
%info__label { // blok textu ve slideru a v mapě - nadpis
	width: $font-size * 13;
	padding: $font-size;
//	background: $color-transparent-secondary;
	box-sizing: border-box;
	position: relative;
//	color: $font-color-contrast;
	z-index: 2;
	@media screen and (max-width: 1024px) {
		width: 100%;
	}
	@media screen and (min-width: 1025px) {
		padding-bottom: $font-size * 2.5;
	}
}
%info__addition { // blok textu ve slideru a v mapě - doplňující text
	@extend %text-medium;
	font-weight: 300;
	display: block;
}
%info__link { // blok textu ve slideru a v mapě - odkaz
	@extend %text-medium;
	margin: 0;
	@media screen and (max-width: 1024px) {
		margin: 0 auto;
	}
	@media screen and (min-width: 1025px) {
		position: absolute;
		width: 100%;
		bottom: 0;
		left: 0;
	}
}

// ----- zástupné třídy END -----

.clear { // prvek clearující obsah kvůli obalení obsahu nadřazeným prvkem (místo float: left a width: 100%)
	clear: both;
}
.float-left { // použitelné pro všechny obtékané prvky, které mají mít odsazení od ostatního obsahu
	float: left;
	padding-right: 2em;
	box-sizing: border-box;
}
.float-right { // použitelné pro všechny obtékané prvky, které mají mít odsazení od ostatního obsahu
	float: right;
	padding-left: 2em;
	box-sizing: border-box;
}
.wrapper { // class použitá pro prvky obsahující obsah, který mají zcela obalovat (místo prvků s .clear)
}
.wide-box { // layoutové prvky, definují vnější okraje webu
	width: $w-wide;
	margin: 0 auto;
	position: relative;
	box-sizing: border-box;
	//max-width: hodnota;// pro případ, kdy layout není widescreenový
}
.simple-box { // layoutové prvky, definují hranice obsahu !!! POZOR - změna poddingu ovlivní .mainmenu__area
	@extend .wide-box;
	@media screen and (max-width: 1024px) {
		padding: $padding-medium $padding-mobile;
	}
	@media screen and (min-width: 1025px) and (max-width: 1365px) {
		padding: $padding-medium $padding-notebook;
	}
	@media screen and (min-width: 1366px) and (max-width: 1599px) {
		padding: $padding-big $padding-desktop;
	}
	@media screen and (min-width: 1600px) and (max-width: 2299px) {
		padding: $padding-big $padding-hd;
	}
	@media screen and (min-width: 2300px) {
		padding: $padding-big $padding-uhd;
	}

	max-width: $w-crop;// omezení šířky elementů
	box-sizing: border-box;
}

.multi-column { // alternativa k .simple-box, použito při vícesloupcovém designu
	@extend .float-left;
	width: $w-medium;
	padding-right: 0;

// ------- page na 100 % START -------
	@media screen and (max-width: 1024px) {
		width: $w-wide;
	}
// ------- page na 100 % END -------
/*// ------- page s aside na 100 % START -------
	@media screen and (max-width: 1599px) {
		width: $w-wide;
		padding-right: 0;
		padding-top: $font-size * 2;
	}
// ------- page s aside na 100 % END -------*/
}
.aside { // postranní sloupec
	@media screen and (max-width: 1024px) {
		display: none;
	}
/*// ------- aside na 100 % START -------
	@media screen and (min-width: 1025px) and (max-width: 1599px) {
		width: $w-wide;
		padding-left: 0;
		.submenu__heading {
			display: none;
		}
	}

// ------- aside na 100 % END -------*/
	@media screen and (min-width: 1025px) {
		width: $w-thin;
	}
}
.homepage, .main { // hlavní obsahové části webu
	@media screen and (max-width: 1024px) {
		min-height: 10 * $font-size;
		margin-bottom: (2 * $font-size);
	}
	@media screen and (min-width: 1025px) {
		margin-bottom: (4 * $font-size);
		min-height: 30 * $font-size;
	}

}