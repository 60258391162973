.homepage h1 {
	text-align: center;
	padding-top: 50px;
	background: url('icon-bee.png') no-repeat center top;
}
.homepage h2 {
	text-align: center;
}
.home-box {
	float: left;
	position: relative;
	margin: $margin-big;
	display: -moz-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	width: 100%;
}
	.home-box__item {
		float: left;
		position: relative;
		-ms-flex-direction: column;
		flex-direction: column;
		-ms-flex-pack: justify;
		justify-content: flex-start;
		z-index: 2;
	}
		.home-box a {
		}

.home-box h2 {
	@extend %heading-medium;
	width: 100%;
	display: block;
}
.home-box h3 {
		font-weight: 300;
		overflow: hidden;
		&:first-line,
		&:first-line {
			font-weight: 600;
		}
}


.home__link-all, .home__link-all span {
	background: $color-background-main;
}
.home__link-all {
	border: 10px solid $color-background-main;
}
.extensions__link-all span {
	background: -moz-linear-gradient(top, $color-background-4 50%, $color-background-4 50%); /* FF3.6-15 */
	background: -webkit-linear-gradient(top, $color-background-4 50%, $color-background-4 50%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom, $color-background-4 50%, $color-background-4 50%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$color-background-4', endColorstr='$color-background-4',GradientType=0 ); /* IE6-9 */

}
.extensions__link-all {
	border: 10px solid $color-background-4;
}

// Uvod

.notice {
	margin-bottom: 3em;
	border-bottom: 1px solid $color-background-2;
}
// Novinky
.news h2 {
	@media screen and (min-width: 480px) {
		margin-bottom: 3rem;
	}
}
.news__item {
	@media screen and (max-width: 479px) {
		width: 100%;
	}
	@media screen and (max-width: 1024px) {
		padding-top: (2 * ($line-height * 1.4em));
		margin-bottom: (2 *$font-size);
	}
	@media screen and (min-width: 480px) {
		width: 47%;
		&:nth-of-type(1) {
			margin-right: 6%;
		}
	}

}
	.new__picture {
		margin-right: 1em;
		@media screen and (max-width: 1024px) {
			margin-top: 1rem;
			width: 40%;
		}
		@media screen and (min-width: 1025px) {
			width: (10 * $font-size);
			height: (16 * $font-size);
		}
	}
	.new__heading {
		clear: none;
		@extend %heading-medium;
		margin-bottom: 1rem;
		line-height: 1.2;
		height: ($line-height * 1.68em);
		@media screen and (max-width: 479px) {
			font-size: 100%;
		}
		@media screen and (max-width: 1024px) {
			margin-top: -($line-height * 1.68em);
		}

		@media screen and (max-width: 1024px) {
		}
		@media screen and (min-width: 1025px) {
		}
	}
	.new__perex {

	}
	.new__publication {
		@extend %text-small;
		font-weight: bold;
	}
// Produkty
.homepage {
	.products {
		@media screen and (max-width: 479px) {
			width: 100%;
		}
		@media screen and (min-width: 480px) {
			width: 102% !important;
			margin-left: -1%;
			margin-right: -1%;
		}
	}
	.products .product_result {
		margin-left: 1%;
		margin-right: 1%;
		box-shadow: none;
		padding-bottom: 50px;
		@media screen and (min-width: 1025px) {
			width: ((100% / 3) - 2%);
		}
		@media screen and (min-width: 1281px) and (max-width: 1600px) {
			padding-left: 4%; padding-right: 4%
		}
		@media screen and (min-width: 1601px) {
			padding-left: 8%; padding-right: 8%;
		}
	}
	.products h3 {
		@extend %text-big;
			line-height: 1.25;
		@media screen and (min-width: 480px) {
			height: ($line-height * 1.7em);
		}
	}
	.products .perex {
		@media screen and (max-width: 479px) {
			height: auto;
		}
		@media screen and (min-width: 480px) {
			height: ($line-height * 4em);
		}
	}
	.products .link-cta {
		position: absolute;
		bottom: 0;
		left: 50%;
	-ms-transform: translateX(-50%);
-webkit-transform: translateX(-50%);
		transform: translateX(-50%);
	}
}



