// BREADCRUMBS
.bread {
	@media screen and (max-width: 1365px) {
		margin-top: -$padding-small;
		margin-bottom: $padding-small;
	}
	@media screen and (min-width: 1366px) {
		margin-top: -$padding-medium;
		margin-bottom: $padding-medium;
	}
}
		.bread__area * {
			@extend %text-small;
		}
		.bread__separator {
			margin: 0 $font-size / 2;
		}

// ORDERING

#page_selecting {
	background: #fff;
	padding: 0;
	margin-top: 0;
	margin-bottom: 0;
}
#page_selecting select {
	padding: 0;
}

// PAGING
.paging {
	@extend %text-medium;
	padding: $padding-small $padding-huge;
	color: $color-background-main;
	margin: $padding-medium auto;
	width: 100%;
	box-sizing: border-box;
	border-top: 1px solid $color-separator;
	border-bottom: 1px solid $color-separator;
}
	.paging a {
		color: $color-link-contrast;
	}
	.paging .active {
		color: $color-text-1;
	}

// REUSLTS
.page_result:last-child,
.article_result:last-child,
.search_result:last-child {
	border-bottom: none;
}
	.page_result .picture,
	.article_result .picture,
	.search_result .picture {
		margin-right: (2*$font-size);
		margin-bottom: $font-size;
		height: 7.5em;
		img {
			height: 100%;
			width: auto;
			min-width: 0;
			max-width: none;

		}
	}


// PUBLICATION
	.publication {
		@extend %text-small;
		font-weight: bold;
		color: $color-text-2;
	}
// FORMS
label {
	@media screen and (max-width: 1024px) {
		font-size: (1em);
	}
	@media screen and (min-width: 1025px) {
		font-size: (1em * 1.125);
	}

}
.input label {
		display: inline;
}
input, select, textarea {
	@extend %text-medium;
	border: 1px solid $color-background-2;
	font-family: $font-family;
	@media screen and (max-width: 1024px) {
		font-size: (1em);
	}
	@media screen and (min-width: 1025px) {
		font-size: (1em * 1.125);
	}

}
input.submit {
	@extend .link-cta;
}

// SENDFORM

.sendform_form form.form,
.sendform_form h2.sendform {
//	color: $color-text-contrast;
	box-sizing: border-box;
	padding: 0;
}
.sendform_form h2.sendform {
	@media screen and (max-width: 479px) {
		padding: $padding-small $padding-medium;
	}
	@media screen and (min-width: 480px) {
		padding: $padding-small $padding-big;
	}
	margin: (3 * $font-size) 0 0 0;
	background: $color-background-3;
}
.sendform_form form.form {
	background: $color-background-4;
	border: 2px solid $color-background-3;
}
.sendform_form .formtab {
	margin: 0;
	@media screen and (max-width: 479px) {
		padding: $padding-medium;
	}
	@media screen and (min-width: 480px) {
		padding: $padding-medium $padding-big;
	}
	table {
	//	margin: 0 auto;
		width: 100%;
	//	text-align: center;
	}
	tr:last-child {
		@media screen and (min-width: 590px) {
			float: right;
		}
		@media screen and (max-width: 589px) {
			td {
				text-align: center;
			}
		}
	}
}
.sendform_form .formtab #mw_tr_subject,
.sendform_form .formtab #mw_tr_sender,
.sendform_form .formtab #mw_tr_captcha {
	float: left;
}
.sendform_form .formtab #mw_tr_subject,
.sendform_form .formtab #mw_tr_captcha {
	@media screen and (min-width: 589px) {
		margin-right: $padding-huge;
	}
}
.sendform_form .formtab td {
	display: block;
	text-align: left;
	padding: 0;
	width: 100%;

}
.sendform_form .formtab .input {
}
.sendform_form .formtab input, .sendform_form .formtab textarea {
	margin: $margin-medium;
}
.sendform_form .formtab textarea {
	padding: 5px 1%;
	height: 150px;
	box-sizing: border-box;
}
.sendform_form .formtab input.submit {
	@extend .link-cta;
	border: none;
	padding: ($padding-small / 2) $padding-big;
	margin-top: ($padding-small * 1.2);
}
@media screen and (max-width: 390px) {
	.sendform_form .formtab input.long {
		width: auto;
	}
}


// FIXOVANE PRVKY
.quick-area--fixed {
	@extend %text-medium;
	font-weight: 600;
}
@media screen and (max-width: 1024px) {
	.quick-area--fixed {
		display: none;
	}
}
@media screen and (min-width: 1025px) {
	.quick-area--fixed {
		position: fixed;
		z-index: 98;
		left: 0;
		top: 50%;
		-ms-transform: translateY(-50%) translateX(-50%) rotate(90deg);
		-webkit-transform: translateY(-50%) translateX(-50%) rotate(90deg);
		transform: translateY(-50%) translateX(-50%) rotate(90deg);
		margin-left: 26px;
	}
		.quick__newsletter--fixed,
		.quick__pharmacy--fixed {
			box-sizing: border-box;
			text-align: center;
			float: left;
			margin-right: 10px;
			box-shadow: 0 0 4px rgba(255,255,255,1);
		}
		.quick__newsletter--fixed {
			background: $color-background-1;
		}
		.quick__pharmacy--fixed {
			background: $color-background-2;
		}
			.quick__newsletter--fixed a {
				cursor: pointer;
				background: url('icon-newsletter-small.png') no-repeat ($padding-medium * 1.5) center;
			}
			.quick__pharmacy--fixed a {
				background: url('icon-pharmacy-small.png') no-repeat ($padding-medium * 1.5) center;
			}
			.quick-area--fixed a,
			.quick-area--fixed span  {
				display: block;
				padding: $padding-medium ($padding-medium * 1.5)  $padding-medium ($padding-medium * 5);
				background-size: 35px auto;
			}
}

// ROZEVIRACE

.link__wrapper { // oblast rozbalovacího odkazu-šipky
	clear: both;
	text-align: center;
	display: block;
	width: 100%;
	border-top: 1px solid $color-background-3;
	position: relative;
	z-index: 1;
	margin: 3em 0 0 0;
	background: $color-background-4;
	visibility: visible;
}
.extensions .link__wrapper {
	position: absolute;
	bottom: 0;
	height: 5em;
}
.news .link__wrapper {
	margin-bottom: 5em;
}
.link__wrapper a { // okrajový prvek tvořící zelený rámeček
	text-indent: -9999px;
	position: absolute;
	left: 50%;
	z-index: 1;
	background: -moz-linear-gradient(top, $color-background-4 50%, $color-background-3 50%); /* FF3.6-15 */
	background: -webkit-linear-gradient(top, $color-background-4 50%, $color-background-3 50%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom, $color-background-4 50%, $color-background-3 50%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$color-background-4', endColorstr='$color-background-3',GradientType=0 ); /* IE6-9 */
	height: 100px;
	width: 100px;
	-ms-transform: translateY(-50%) translateX(-50%);
	-webkit-transform: translateY(-50%) translateX(-50%);
	transform: translateY(-50%) translateX(-50%);
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	border-radius: 50%;
	&:before { // vnitřní prvek tvořící zelené kolečko
		content: ' ';
		-webkit-border-radius: 50%;
		-moz-border-radius: 50%;
		border-radius: 50%;
		position: absolute;
		bottom: 20px;
		left: 31px;
		height: 38px;
		width: 38px;
		background: $color-background-1;
		z-index: 3;
	}
	&:after { // bílá šipka v zeleném kolečku
		content: '';
		display: block;
		border: solid $color-background-main;
		border-width: 0 2px 2px 0;
		display: inline-block;
		padding: 6px;
		position: absolute;
		top: 51px;
		left: 51px;
		transform: rotate(45deg);
		z-index: 4;
		margin-left: -8px;
		box-sizing: border-box;
	}
	&:hover:before {
		background: $color-background-3;
	}
}
	.link__wrapper span { // vnitřní prvek tvořící zelené kolečko s bílým okrajem
		content: ' ';
		-webkit-border-radius: 50%;
		-moz-border-radius: 50%;
		border-radius: 50%;
		position: absolute;
		bottom: 1px;
		left: 1px;
		height: 98px;
		width: 98px;
		z-index: 2;
	}