.slider {
	margin: 0 auto;
	overflow: hidden;
	position: relative;
	width: $w-wide;
	z-index: 1;
}
	.slider__area {
		margin: 0 auto;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 2;
	}
		.slider__item {
			left: 0;
			list-style: none;
			position: relative;
			top: 0;
			width: $w-wide;
			 //position: absolute;
		}
			.slider__image {
				position: relative;
				width: $w-wide;
				z-index: 1;
				display: block;
			}
			.slider__text {
				@media screen and (max-width: 479px) {
				}
				@media screen and (min-width: 740px) {
					padding: $padding-medium $padding-big;
				}
			}
				.slider__heading {
					text-transform: uppercase;
					display: block;
					color: $color-link-contrast;
					@media screen and (max-width: 479px) {
						font-size: 6.7vw;
					}
					@media screen and (min-width: 480px) and (max-width: 1024px) {
						font-size: 6.2vw;
					}
					@media screen and (min-width: 1025px) {
						font-size: 4.7vw;
					}
				}
				.slider__addition {
					text-transform: uppercase;
					.black {
						font-size: 120%;
					}
					span {
						@media screen and (max-width: 1280px) {
							display: block;
						}
					}
					@media screen and (max-width: 479px) {
						font-size: 4vw;
						margin-bottom: 2%;
					}
					@media screen and (min-width: 480px) and (max-width: 1024px) {
						margin-bottom: 6%;
					}
					@media screen and (min-width: 480px) and (max-width: 1280px) {
						font-size: 3.5vw;
					}
					@media screen and (min-width: 1025px) and (max-width: 1280px) {
						margin-bottom: 12%;
						font-size: 3vw;
					}
					@media screen and (min-width: 1281px) {
						font-size: 2vw;
					}
					@media screen and (min-width: 1281px) and (max-width: 1599px) {
						margin-bottom: 8%;
					}
					@media screen and (min-width: 1600px) {
						margin-bottom: 10%;
					}
				}
				.slider__link {
					float: left;
					font-weight: normal;
					@media screen and (max-width: 479px) {
						float: left;
					}
					@media screen and (max-width: 739px) {
						padding: 5px 20px;
						margin-top: 6%;
						font-size: $font-size;
					}
					@media screen and (min-width: 740px) {
						padding: 0.5vw 1.5vw;
						font-size: 2vw;
					}
					@media screen and (min-width: 740px) and (max-width: 1280px) {
						margin-top: 8%;
					}
					@media screen and (min-width: 1281px) {
						margin-top: 5%;
					}
				}
			.slider__products {
				float: left;
			}
				.slider__product {
					height: auto;
					@media screen and (max-width: 479px) {
						display: none;
					}
					@media screen and (min-width: 480px) and (max-width: 779px) {
						width: 15vw;
					}
					@media screen and (min-width: 480px) and (max-width: 1024px) {
						margin-right: 4vw;
					}
					@media screen and (min-width: 780px) and (max-width: 1024px) {
						width: 13vw;
					}
					@media screen and (min-width: 1025px) and (max-width: 1280px) {
						margin-right: 6vw;
						width: 13vw;
					}
					@media screen and (min-width: 1281px) {
						margin-right: 6vw;
						width: 10vw;
					}
				}